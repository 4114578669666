/*
Generic queue object that makes it easy to retrieve the first element in the queue
 */

/**
 * @constructor
 */
function Queue() {
  this._queue = [];
}

/**
 * Add a new item to the end of the queue
 *
 * @param {*} data
 */
Queue.prototype.add = function(data) {
  this._queue.push(data);
};

/**
 * Shift the first item from the top of the queue and return it
 *
 * @returns {* | undefined}
 */
Queue.prototype.getNext = function() {
  return this._queue.shift();
};

/**
 * Check the number of items currently in the queue
 *
 * @returns {Number}
 */
Queue.prototype.getSize = function() {
  return this._queue.length;
};

/**
 * Check whether or not there are any items in the queue
 * @returns {boolean}
 */
Queue.prototype.isEmpty = function() {
  return this._queue.length === 0;
};

/**
 * Check what the next item in the queue is without actually removing it.
 *
 * @returns {* | undefined}
 */
Queue.prototype.peek = function() {
  if (this._queue.length > 0) {
    return this._queue[0];
  }

  return undefined;
};

/**
 * Instantiate and return a new Queue object
 *
 * @returns {Queue}
 */
const create = function() {
  return new Queue();
};

module.exports = {
  create,
};
