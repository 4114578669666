/**
 * Highlighter Module Enums
 */
import keyMirror from 'optly/utils/key_mirror';

export const IFrameHighlightTypes = keyMirror({
  DRAFT: null,
  LIVE: null,
  STAGED: null,
  HOVERED: null,
  SELECTED: null,
});

export default {
  IFrameHighlightTypes,
};
