const tr = require('l10n-tr');

if (window.optlyL10n) {
  if (window.optlyL10n.locale) {
    // If locale is given - load it
    tr.setLocale(window.optlyL10n.locale);
  }
  if (window.optlyL10n.dict) {
    // If dictionary is given - load it
    tr.setDict(window.optlyL10n.dict);
  }
}
module.exports = tr;
// Make it also available globally do we won't need to include it everywhere
window.tr = tr;
