import WebMessageChannel from 'bundles/p13n/modules/editor_iframe/message_channel';

let DesktopMessageChannel;

if (__DESKTOP__) {
  DesktopMessageChannel = require('./desktop_inner_channel').default; // eslint-disable-line global-require
}

export default __DESKTOP__ ?
  new DesktopMessageChannel() :
  new WebMessageChannel();
