const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

/**
 * URL -> protocol map
 */
module.exports = Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(
      actionTypes.P13N_EDITOR_IFRAME_REGISTER_PROTOCOL_FOR_URL,
      registerProtocolForUrl,
    );
    this.on(
      actionTypes.P13N_EDITOR_IFRAME_UNREGISTER_PROTOCOL_FOR_URL,
      unregisterProtocolForUrl,
    );
  },
});

/**
 * register a url to iframe protocol mapping (to enable proxy timeout shortcutting)
 *
 * @param {object} state
 * @param {object} payload
 * @param {number} payload.url
 * @param {string} payload.protocol
 */
function registerProtocolForUrl(state, payload) {
  return state.set(payload.url, payload.protocol);
}

/**
 * unregister a url to iframe protocol mapping
 * @param {object} state
 * @param {object} payload
 * @param {number} payload.url
 */
function unregisterProtocolForUrl(state, payload) {
  return state.delete(payload.url);
}
