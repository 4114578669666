import assign from 'lodash/assign';
import Promise from 'es6-promise';

const Deferred = function() {
  let resolver;
  let rejecter;
  const p = new Promise(((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  }));
  return assign(p, {
    resolve(value) {
      resolver(value);
      return p;
    },
    reject(value) {
      rejecter(value);
      return p;
    },
  });
};

export default Deferred;
