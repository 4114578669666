/**
 * Editor Iframe MessageChannel Class
 * this is used for communication channels with inner
 *
 * WARNING: this file is currently being used from the p13n_inner bundle.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 */

const domain = require('optly/modules/domain').default;

const OPTLY_P13N_MESSAGE_TYPE = 'OPTLY_P13N_MESSAGE';

/**
 * message channel constructor
 */
function MessageChannel(validateOrigin) {
  this.__listeners = [];
  this.__validateOrigin = validateOrigin || false;
}

/**
 * write data to a message channel instance (ie. call all listeners on data)
 * @param {object} data [description]
 */
MessageChannel.prototype.write = function(message, source) {
  [].forEach.call(this.__listeners || [], f => {
    f(message, source);
  });
};

/**
 * add a listener to a message channel instance
 * @param {function} listener
 * @return {function} listener removal function
 */
MessageChannel.prototype.listen = function(listener) {
  this.__listeners.push(listener);
  return function unlisten() {
    const ind = this.__listeners.indexOf(listener);
    this.__listeners.splice(ind, 1);
  }.bind(this);
};

/**
 * set up message channel as a write channel, posting to a window with a given id
 * @param {object} win the window to write to
 * @param {string} id  "To:" component address identifier (the id of the component that listens on the channel)
 */
MessageChannel.prototype.registerWriter = function(win, id) {
  return this.listen(message => {
    const restorePrototypeJS = this.handlePrototypeJS();
    const msg = JSON.stringify(message);
    // In safari, postMessage is not defined when the user pops the iframe back into the editor
    // after having popped it out
    if (win.postMessage) {
      win.postMessage(
        {
          id,
          type: OPTLY_P13N_MESSAGE_TYPE,
          message: msg,
        },
        '*',
      );
    } else {
      console.warn('{Loader] Attempted to message window without .postMessage defined.'); // eslint-disable-line
    }

    restorePrototypeJS();
  });
};

/**
 * set up message channel as a read channel, listening on a window with a given id
 * @param {object} win the window to listen for messages to
 * @param {string} id  identifier to determine which messages to listen to
 */
MessageChannel.prototype.registerReader = function(win, id) {
  const self = this;
  win.addEventListener('message', e => {
    if (e.data.type === OPTLY_P13N_MESSAGE_TYPE && (!id || e.data.id === id)) {
      const origin = e.origin || e.originalEvent.origin;
      const message = JSON.parse(e.data.message);
      const source = e.source;

      if (self.__validateOrigin && !domain.check(origin)) {
        return;
      }
      self.write(message, source);
    }
  });
};

/**
 * destroy the message channel and by removing listeners
 */
MessageChannel.prototype.destroy = function() {
  delete this.__listeners;
};

/**
 * Deal with Prototype.js bizareness and return a function that undoes the change.
 * @return {Function}
 */
MessageChannel.prototype.handlePrototypeJS = function() {
  // Some versions of Prototype ship with a broken Array.toJSON
  // which flattens arrays into strings! Since we have a working
  // JSON.stringify we can safely remove it.
  // See: http://stackoverflow.com/questions/710586/json-stringify-bizarreness
  if (window.Prototype && Array.prototype.toJSON) {
    const _array_toJSON = Array.prototype.toJSON;
    delete Array.prototype.toJSON;
    return function() {
      Array.prototype.toJSON = _array_toJSON; // eslint-disable-line
    };
  }
  return function() {};
};

module.exports = MessageChannel;
