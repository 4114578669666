/*
 * WARNING: this file is currently being used from the p13n_inner bundle.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 */

/* eslint-disable import/no-commonjs */

const flux = require('core/flux');

const actionTypes = require('./action_types');

/**
 * Enables or disables editor iframe logging.
 * @param {Boolean} enabled
 */
exports.setLoggingEnabled = function(enabled) {
  flux.dispatch(actionTypes.P13N_EDITOR_SET_LOGGING_ENABLED, {
    enabled,
  });
};

/**
 * Logs a emit message for a particular EditorIframe
 * @param {String} id
 * @param {Object} message
 */
exports.logEmit = function(id, message) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_LOG, {
    id,
    category: 'emit',
    message,
  });
};

/**
 * Logs a write for a particular EditorIframe
 * @param {String} id
 * @param {Object} message
 */
exports.logWrite = function(id, message) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_LOG, {
    id,
    category: 'write',
    message,
  });
};

/**
 * Logs a write for a particular EditorIframe
 * @param {String} id
 * @param {Object} message
 * @param {Boolean} logToConsole
 */
exports.log = function(id, message, logToConsole) {
  flux.dispatch(actionTypes.P13N_EDITOR_IFRAME_LOG, {
    id,
    category: 'log',
    message,
  });

  if (logToConsole && !__TEST__) {
    window.console && window.console.debug && window.console.debug(message);  // eslint-disable-line
  }
};
