/**
 * Simple wrapper around immutable-js providing the convenience of `isImmutable` and `toImmutable` named
 * exports.  This wrapper will no longer be needed once we upgrade to immutable v4.x, which includes an
 * `isImmutable` export as part of the standard API.
 */
import Immutable from 'immutable';

const { fromJS, is, Map } = Immutable;

// convenience `isImmutable` function
// per: https://stackoverflow.com/questions/31907470/how-to-check-if-object-is-immutable
const isImmutable = Immutable.Iterable.isIterable;

// convenience `toImmutable` function
function toImmutable(arg) {
  return isImmutable(arg) ? arg : fromJS(arg);
}

// convenience `toJS` function
function toJS(arg) {
  return isImmutable(arg) ? arg.toJS() : arg;
}

function toMap(arg) {
  return isImmutable(arg) ? arg : Map(arg);
}

// note: these re-mapped named exports not an exhaustive list, just including the exports
// currently being used in the monolith FE code.  We may want to map more as needed.
export { fromJS, is, isImmutable, toJS, toImmutable, toMap };

export default Immutable;
