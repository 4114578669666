/**
 * p13n Inner functions.
 *
 * NOTE: this entrypoint is exposed on customer websites and we are intentionally not applying es6 polyfills.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.  Ideally, no modules should be imported from within another bundle's
 * source files.
 */

import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';

import $ from 'jquery';
import config from 'atomic-config';

import flux from 'core/flux';

import queryHelpers from 'optly/utils/query_selector';
import shadowDomUtils from 'optly/utils/shadow_dom';
import tr from 'optly/translate';

import Highlighter from 'optly/modules/highlighter';
import DesktopAppFns from 'optly/modules/desktop_app/fns';
import EditorConstants from 'bundles/p13n/modules/editor/constants';
import EditorIframeLogActions from 'bundles/p13n/modules/editor_iframe/log_actions';
import EditorIframeEnums from 'bundles/p13n/modules/editor_iframe/enums';
import EditorIframeStores from 'bundles/p13n/modules/editor_iframe/stores';

import Deferred from './deferred';

import ChangeHandler from './change_handler';
import enums from './enums';
import readChannel from './read_channel';
import { generateSelector as selectorator } from './selectorator';
import utils from './utils';
import writeChannel from './write_channel';

let bootstrapInitialized = false;

let defaultExport;

window.optlyHighlight = Highlighter;

/**
 * Account ID exposed in client-js or microsnippet client.
 * @type {String}
 */
const clientAccountId =
  window.optimizelyDataApi &&
  window.optimizelyDataApi.getAccountId &&
  String(window.optimizelyDataApi.getAccountId());

/**
 * Project ID exposed in client-js or microsnippet client.
 * @type {String}
 */
const clientProjectId =
  window.optimizelyDataApi &&
  window.optimizelyDataApi.getProjectId &&
  String(window.optimizelyDataApi.getProjectId());

const errorMessages = {
  optimizelyEdge:
    'You must target a page that has Edge implemented to apply this extension',
};

/**
 * Bootstrap the editor version of client so that we can apply changes
 * @param {string} dataEndpoint Optimizely app endpoint from which to retrieve draft data
 * @param {string} editorClientSrc Endpoint that will build editor version of client
 * @returns {Deferred} Resolved when the client has loaded and initialized
 */
export function bootstrapEditorClient(dataEndpoint, editorClientSrc) {
  const clientDeferred = Deferred();
  // Set up client data endpoint
  window.optimizely_editor_data_endpoint = dataEndpoint;

  // Add listener for client ready message
  window.optimizely = window.optimizely || [];

  window.optimizely.push({
    type: 'addListener',
    filter: {
      type: 'editor',
      name: 'ready',
    },
    handler: clientDeferred.resolve.bind(clientDeferred),
  });

  // bootstrap editor client -- NOTE: using a this custom bootstrapping method rather than
  // $.getScript to avoid issues with rewriter.js. TODO(sam) -- make sync?
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.setAttribute('data-optlyIgnore', true);
  scriptTag.src = editorClientSrc;
  const headTag = document.getElementsByTagName('head')[0];
  headTag.insertBefore(scriptTag, headTag.childNodes[0]);
  return clientDeferred;
}

/**
 * Helper method for resetting the inner state during testing.
 */
export function resetInner() {
  bootstrapInitialized = false;
}

/**
 * Main entry point for running inner, called via main.js
 * (helpful for reinitializing a run of inner while testing.)
 */
export function run() {
  let id = null;
  let currentMode = EditorIframeEnums.IFrameModeTypes.STANDARD;
  let outerId = null;
  let clientDataEndpoint = null;
  let editorClientSrc = null;
  let lastKnownHoverElement = null;
  const OPTLY_PAGE_OVERLAY_CLASS = 'optly-page-overlay';

  flux.registerStores(EditorIframeStores);

  function initialize() {
    window.optimizely_p13n_inner = window.optimizely_p13n_inner || {};

    // If this isnt the desktop bundle, p13n_inner should only run if window.optlyDesktop
    // is not defined, indicating were not running in the desktop app.
    const shouldExecute = __DESKTOP__ ? true : !DesktopAppFns.isDesktopApp();

    if (!window.optimizely_p13n_inner.loaded && shouldExecute) {
      window.optimizely_p13n_inner.loaded = true;

      window.optly$ = $;
      setupReadChannel();
      setupEventListeners();
      Highlighter.appendStlyesheet();
      // Run cleanse again after site has loaded to remove all properties from
      // tainted prototypes that may interfere with editor.
      $(() => {
        // The Optimizely marketing pages override window.optly (bad), so explicitly check for Cleanse.
        window.optly && window.optly.Cleanse && window.optly.Cleanse.start();
      });
    } else {
      window.console.warn('Optimizely p13n inner has already been loaded.');
    }
  }

  /**
   * get dom/css info for all elements that match a given selector
   * @param {string} selector
   * @return {Array<ElementInfo>}
   */
  function getElementInfo(selector) {
    // Get element info for only the first element returned by the selector.
    // Leave elementInfo as a list for now as we may decide to leverage having the elementInfo
    // for all elements in the future. But until then, only return the info for the first element.
    const elements = queryHelpers.querySelectorAll(selector);

    // Use change applier, setup in bootstrapEditorClient, to return a flattened list of selectors that contain change data
    // This will prevent users from using the editor sidebar to overwrite existing Optimizely changes
    const changeApplier = window.optimizely.get('client-change-applier');
    let selectorsWithChangeData = [];
    if (changeApplier.identifyChildrenWithChangeData) {
      const elementsWithChangeDataLists = map(
        elements,
        changeApplier.identifyChildrenWithChangeData,
      );
      const elementsWithChangeDataList = flattenDeep(
        elementsWithChangeDataLists,
      );
      selectorsWithChangeData = map(elementsWithChangeDataList, selectorator);
    }

    return (elements.length ? [elements[0]] : []).map(element => {
      const parentAnchor = $(element).parents('a');
      const parentBackgroundElement = getParentElementWithBackgroundImage(
        element,
      );
      let parentBackgroundImage = null;
      let parentBackgroundSelector = null;
      let parentSelector = null;
      let parentHref = null;

      if (parentAnchor.length === 1) {
        parentSelector = selectorator(parentAnchor[0]);
        parentHref = parentAnchor.attr('href');
      }

      if (parentBackgroundElement !== null) {
        parentBackgroundImage = window.getComputedStyle(parentBackgroundElement)
          .backgroundImage;
        parentBackgroundSelector = selectorator(parentBackgroundElement);
      }

      const computedStyle = window.getComputedStyle(element);
      const trimmedComputedStyle = {};

      Object.keys(EditorConstants.CSSPropertyNames)
        .map(key => EditorConstants.CSSPropertyNames[key])
        .forEach(prop => {
          trimmedComputedStyle[prop] = computedStyle.getPropertyValue(prop);
        });

      return {
        nodeName: element.nodeName,
        class: element.getAttribute('class'),
        style: {
          cssText: element.style.cssText,
        },
        html: element.innerHTML,
        outerHtml: element.outerHTML,
        href: $(element).attr('href'),
        text: element.textContent,
        src: element.src,
        computedStyle: trimmedComputedStyle,
        parentAnchor: {
          href: parentHref,
          selector: parentSelector,
        },
        parentBackground: {
          backgroundImage: parentBackgroundImage,
          selector: parentBackgroundSelector,
        },
        selector,
        selectorsWithChangeData,
      };
    });
  }

  /**
   * Return the following information about this selector:
   *    - Array of elementInfo
   *    - Count of the number of elements returned
   * @param selector
   */
  function getSelectorInfo(selector) {
    return {
      elementInfo: getElementInfo(selector),
      elementCount: queryHelpers.querySelectorAll(selector).length,
    };
  }

  /**
   * click handler writes info message to its manager
   * @param {object} event - The click event
   */
  function handleClick(event) {
    unHover();
    const element = getElementFromPoint(event);
    const selector = selectorator(element);
    writeChannel.write({
      type: EditorIframeEnums.IFrameMessageTypes.CLICK,
      payload: {
        clientX: event.clientX,
        clientY: event.clientY,
        selector,
        selectorInfo: getSelectorInfo(selector),
      },
    });
  }

  /**
   * hover event handler
   * Keep track of the most recent element we hovered over. If a mousemove event stays
   * on the same element, we dont need to handle it again.
   * @param {object} event - The hover event
   */
  function handleHover(event) {
    const element = getElementFromPoint(event);
    if (element && element !== lastKnownHoverElement) {
      const selector = selectorator(element);
      Highlighter.unhighlight({
        type: Highlighter.enums.IFrameHighlightTypes.HOVERED,
      });
      Highlighter.highlight({
        type: Highlighter.enums.IFrameHighlightTypes.HOVERED,
        selector,
      });

      writeChannel.write({
        type: EditorIframeEnums.IFrameMessageTypes.HOVER,
        payload: {
          selector,
          selectorInfo: getSelectorInfo(selector),
        },
      });
    }
    lastKnownHoverElement = element;
  }

  /**
   * Unhover any currently hovered elements and set the last known hover element to null.
   */
  function unHover() {
    Highlighter.unhighlight({
      type: Highlighter.enums.IFrameHighlightTypes.HOVERED,
    });
    lastKnownHoverElement = null;
  }

  /**
   * Get an element from a point by hiding the body overlay first, then reapplying it.
   * @param event
   * @returns {HTMLElement}
   */
  function getElementFromPoint(event) {
    $(`.${OPTLY_PAGE_OVERLAY_CLASS}`).attr('style', 'width: 0 !important');
    const element = shadowDomUtils.isShadowDomSupported()
      ? shadowDomUtils.elementFromPoint(event.clientX, event.clientY)
      : document.elementFromPoint(event.clientX, event.clientY);
    $(`.${OPTLY_PAGE_OVERLAY_CLASS}`).attr('style', '');
    return element;
  }

  /**
   * Handle all bound events
   * @param {Object} event
   * @private
   */
  function handleEvent(event) {
    if (currentMode === EditorIframeEnums.IFrameModeTypes.NO_HOVER) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (currentMode === EditorIframeEnums.IFrameModeTypes.STANDARD) {
      if (event.type === 'contextmenu') {
        return false;
      }

      // For scroll bars in Chrome.
      if (event.type === 'mousedown' && event.target.tagName === 'HTML') {
        handleClick(event);
        return true;
      }

      // Prevent mouse events from propagating through the optly overlay.
      event.preventDefault();
      event.stopPropagation();

      switch (event.type) {
        case 'keyup':
          // this.handleKeyUp(event);
          break;
        case 'mousedown':
          handleClick(event);
          break;
        case 'mouseleave':
          unHover();
          break;
        case 'mousemove':
          handleHover(event);
          break;
        default:
          return false;
      }
    }
  }

  function getEvalErrorMessage(error, window, message) {
    let errorMessage = `${error.name}: ${error.message}`;
    if (!window.optimizelyEdge && message.isOptimizelyEdgeTextInTargetCode) {
      errorMessage = errorMessages.optimizelyEdge;
    }
    return errorMessage;
  }

  function handleEvalRequest(message) {
    // use Function constructor here since it doesn't include local closured scope like `eval`
    // see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function

    const fn = new Function(message.payload.code); // eslint-disable-line no-new-func
    let error = null;
    try {
      fn.call(window);
    } catch (e) {
      error = getEvalErrorMessage(e, window, message);
    } finally {
      resolveDeferredMessage(message, {
        error,
      });
    }
  }

  function setupReadChannel() {
    readChannel.registerReader(window);
    readChannel.listen((message, source) => {
      console.debug(`[P13N INNER][MESSAGE] ${message.type} - Payload:`); // eslint-disable-line
      console.debug(message.payload); // eslint-disable-line
      switch (message.type) {
        case EditorIframeEnums.IFrameMessageTypes.REGISTER:
          // Inner gets spammed with REGISTER messages from editor_iframe, so make sure we only
          // bootstrap the editor client once, and therefore only send a ready message once.
          if (!bootstrapInitialized) {
            bootstrapInitialized = true;

            window.optly_inner_features = message.payload.features;
            outerId = message.payload.outerId;
            id = message.payload.innerId;
            clientDataEndpoint = message.payload.clientDataEndpoint;
            editorClientSrc = message.payload.editorClientSrc;
            writeChannel.registerWriter(source, outerId);
            writeChannel.write({
              type: EditorIframeEnums.IFrameMessageTypes.HELLO,
              payload: {
                id,
              },
            });

            if (
              message.payload.frameType === EditorIframeEnums.FrameTypes.POPOUT
            ) {
              $(window).one('beforeunload', handleBeforeUnloadPopout);
            }

            // If there is a currentAccountId, that means that the site was loaded over the HTTP/HTTPS.
            // See editor_iframe_web.js to see how we registered it.
            // We therefore check to see that the accountId matches the currentAccountId.
            if (
              isUnrecognizedClient(
                message.payload.currentAccountId,
                message.payload.trustedProjectIds,
              )
            ) {
              handleUnrecognizedClient();
            } else {
              defaultExport
                .bootstrapEditorClient(clientDataEndpoint, editorClientSrc)
                .then(handleEditorClientBootstrapped);
            }
          }
          break;
        case EditorIframeEnums.IFrameMessageTypes.HIGHLIGHT_ELEMENT:
          Highlighter.highlight({
            type: message.payload.type,
            dataOptlyId: message.payload.dataOptlyId,
            selector: message.payload.selector,
            label: message.payload.label,
            hostUrl: config.get('env.HOST_URL'),
            overlayClass: getOverlayClass(message.payload.selectorType),
          });
          break;
        case EditorIframeEnums.IFrameMessageTypes.UNHIGHLIGHT_ELEMENT:
          Highlighter.unhighlight({
            type: message.payload.type,
            dataOptlyId: message.payload.dataOptlyId,
            selector: message.payload.selector,
          });
          break;
        case EditorIframeEnums.IFrameMessageTypes.EVAL:
          handleEvalRequest(message);
          break;
        case EditorIframeEnums.IFrameMessageTypes.APPLY_CHANGES:
          ChangeHandler.pushChangeHandlerRequest(
            enums.ChangeHandlerRequestQueueType.APPLY_CHANGES,
            {
              changeSet: message.payload.changes,
              id: outerId,
            },
          ).then(Highlighter.rerender);
          break;
        case EditorIframeEnums.IFrameMessageTypes.FETCH_ELEMENT_INFO:
          ChangeHandler.pushChangeHandlerRequest(
            enums.ChangeHandlerRequestQueueType.EXECUTE_WITHOUT_CHANGES,
            {
              functionToExecute: () =>
                getSelectorInfo(message.payload.selector),
              partialUndoChangeId: message.payload.partialUndoChangeId,
            },
          ).then(resolveData => resolveDeferredMessage(message, resolveData));
          break;
        case EditorIframeEnums.IFrameMessageTypes
          .FETCH_RELATED_ELEMENT_SELECTORS:
          resolveDeferredMessage(
            message,
            utils.getRelatedElementSelectors(message.payload.selector),
          );
          break;
        case EditorIframeEnums.IFrameMessageTypes
          .APPLY_CHANGES_AND_FETCH_ELEMENT_INFO:
          ChangeHandler.pushChangeHandlerRequest(
            enums.ChangeHandlerRequestQueueType.APPLY_CHANGES,
            {
              changeSet: message.payload.changes,
            },
          ).then(() => {
            ChangeHandler.pushChangeHandlerRequest(
              enums.ChangeHandlerRequestQueueType.EXECUTE_WITHOUT_CHANGES,
              {
                functionToExecute: () =>
                  getSelectorInfo(message.payload.selector),
                partialUndoChangeId: message.payload.partialUndoChangeId,
              },
            )
              .then(resolveData => resolveDeferredMessage(message, resolveData))
              .then(Highlighter.rerender);
          });
          break;
        case EditorIframeEnums.IFrameMessageTypes.SET_MODE:
          setMode(message.payload.mode);
          break;
        case EditorIframeEnums.IFrameMessageTypes.RELOAD:
          window.location.reload();
          break;
        case EditorIframeEnums.IFrameMessageTypes.DESTROY:
          readChannel.destroy();
          writeChannel.destroy();
          break;
        case EditorIframeEnums.IFrameMessageTypes.FETCH_TAG_VALUE:
          resolveDeferredMessage(message, getTagValue(message.payload.tag));
          break;
        case EditorIframeEnums.IFrameMessageTypes.TEST_SELECTOR_EQUALITY:
          resolveDeferredMessage(
            message,
            testSelectorEquality(
              message.payload.selector,
              message.payload.selectorsToTest,
            ),
          );
          break;
        default:
      }
    });
  }

  /**
   * Sends back a generic resolve deferred message for message types that expect a response, eg FETCH_ELEMENT_INFO and FETCH_TAG_VALUE
   * @param {Message} message with message.promiseId
   * @param {*} resolveData
   */
  function resolveDeferredMessage(message, resolveData) {
    if (!message.promiseId) {
      throw new Error(
        'Can only resolve deferred messages where promiseId is present',
      );
    }

    writeChannel.write({
      type: EditorIframeEnums.IFrameMessageTypes.RESOLVE_DEFERRED,
      // relay the promiseId back so outer can resolve the fetchElementInfo promise
      promiseId: message.promiseId,
      payload: {
        resolveData,
      },
    });
  }

  /**
   * Switches modes
   * @param {string} mode - The current mode type.
   */
  function setMode(mode) {
    switch (mode) {
      case EditorIframeEnums.IFrameModeTypes.INTERACTIVE:
        $(`.${OPTLY_PAGE_OVERLAY_CLASS}`).hide();
        Highlighter.showElementHighlighters(false);
        currentMode = EditorIframeEnums.IFrameModeTypes.INTERACTIVE;
        if (!__DESKTOP__) {
          $(window).on('beforeunload', handleBeforeUnloadInteractive);
        }
        break;
      case EditorIframeEnums.IFrameModeTypes.NO_HOVER:
        $(`.${OPTLY_PAGE_OVERLAY_CLASS}`).show();
        Highlighter.showElementHighlighters(true);
        currentMode = EditorIframeEnums.IFrameModeTypes.NO_HOVER;
        if (!__DESKTOP__) {
          $(window).off('beforeunload', handleBeforeUnloadInteractive);
        }
        break;
      default:
        $(`.${OPTLY_PAGE_OVERLAY_CLASS}`).show();
        Highlighter.showElementHighlighters(true);
        currentMode = EditorIframeEnums.IFrameModeTypes.STANDARD;
        if (!__DESKTOP__) {
          $(window).off('beforeunload', handleBeforeUnloadInteractive);
        }
    }
  }

  /**
   * Setup the necessary event listeners on the page to handle mouse
   * events and window resizing.
   */
  function setupEventListeners() {
    $('<OptlyOverlay></OptlyOverlay>', {
      class: OPTLY_PAGE_OVERLAY_CLASS,
    }).appendTo(document.body);
    const eventList =
      'click contextmenu mousedown mouseleave mousemove mouseup';
    $('body').bind(eventList, handleEvent);

    // Rerender the overlay element on window scroll and resize.
    // This is an expensive operation when there are lots of elements highlighted,
    // so debounce it to no more than once per 500ms.
    $(window).on(
      'scroll resize',
      debounce(() => {
        if (currentMode !== EditorIframeEnums.IFrameModeTypes.INTERACTIVE) {
          setTimeout(Highlighter.rerender, 50);
        }
      }, 500),
    );
  }

  /**
   * Verify that the production client loaded on the page is the trusted account ID.
   * If no accountId is exposed by the client, then check the projectId.
   * Eventually, all clients in use should expose the accountId, and we can deprecate
   * the projectId check.
   * @param {string} currentAccountId
   */
  function isUnrecognizedClient(currentAccountId, trustedProjectIds) {
    let isUnrecognized;
    if (clientAccountId && currentAccountId) {
      EditorIframeLogActions.log(id, '[Loader] Verifying account ID.', true);
      isUnrecognized =
        currentAccountId &&
        clientAccountId &&
        currentAccountId !== clientAccountId;
    } else if (
      trustedProjectIds &&
      trustedProjectIds.length &&
      clientProjectId
    ) {
      EditorIframeLogActions.log(id, '[Loader] Verifying project IDs.', true);
      isUnrecognized =
        trustedProjectIds &&
        clientProjectId &&
        !includes(trustedProjectIds, clientProjectId);
    }
    return isUnrecognized;
  }

  /**
   * Handles window navigation event 'beforeunload', returning a custom message to help the user understand
   * why they can't navigate to another page in the editor.
   * @param event - The event used by electron whose returnValue key must be set to false to prevent navigation.
   * @returns {string} The message to show the user in the browser alert box.
   */
  function handleBeforeUnloadInteractive() {
    if (!__DESKTOP__) {
      return tr(
        'Oops! The Optimizely Editor doesn\'t support navigating to another page.\n\nPlease press "{0}"',
        navigator.userAgent.indexOf('Chrome') !== -1
          ? EditorIframeEnums.BrowserBeforeUnloadMessages.CHROME
          : EditorIframeEnums.BrowserBeforeUnloadMessages.NON_CHROME,
      );
    }
  }

  /**
   * Sends a message to the editor that the editor client has bootstrapped
   * @param {string} iframeId
   * @param {string} clientAccountId
   */
  function handleEditorClientBootstrapped() {
    EditorIframeLogActions.log(
      id,
      '[Loader] Editor client bootstrapped.',
      true,
    );
    writeChannel.write({
      type: EditorIframeEnums.IFrameMessageTypes.CONTENT_READY,
      payload: {
        id,
        verifiedId: clientAccountId || clientProjectId,
      },
    });
  }

  /**
   * Sends a message to the editor that loading failed because the client is unrecognized.
   * @param {string} iframeId
   * @param {string} clientAccountId
   */
  function handleUnrecognizedClient() {
    writeChannel.write({
      type: EditorIframeEnums.IFrameMessageTypes.SECURITY_EXCEPTION,
      payload: {
        id,
        mismatchedId: clientAccountId || clientProjectId,
      },
    });
  }

  /**
   * Sends a message to the editor to pop the window back in when the popout is manually closed.
   */
  function handleBeforeUnloadPopout() {
    writeChannel.write({
      type: EditorIframeEnums.IFrameMessageTypes.POP_IN,
      payload: {
        id,
      },
    });
  }

  /**
   * Parse the value for a given tag
   * @param {object} tag
   * @return {string}
   */
  function getTagValue(tag) {
    const TagParser = window.optimizely.get('client-tags');
    if (!TagParser) {
      throw new Error('Failed to load tag parser!');
    }

    const formattedTag = {
      apiName: tag.api_name,
      category: tag.category,
      locator: tag.locator,
      locatorType: tag.locator_type,
      valueType: tag.value_type,
    };

    if (tag.locator_type === TagParser.enums.locatorType.JAVASCRIPT) {
      formattedTag.locator = evaluateTagCode(tag.locator);
    }

    try {
      return TagParser.getTagValue(formattedTag);
    } catch (error) {
      // value cannot be parsed
    }
  }

  /**
   * Given a selector and an array of selectors, return the subset of selectors from the supplied array that
   * are functionally equivalent to the first argument (functionally equivalent meaning the selectors match
   * the same elements)
   *
   * @param {string} selector
   * @param {string[]} selectorsToMatch
   * @returns {string[]}
   */
  function testSelectorEquality(selector, selectorsToMatch) {
    const identicalSelectors = [];
    const selectorElements = queryHelpers.querySelectorAll(selector);
    if (selectorElements.length) {
      selectorsToMatch.forEach(currentSelector => {
        const elementsToMatch = queryHelpers.querySelectorAll(currentSelector);
        if (elementsToMatch.length === selectorElements.length) {
          for (let x = 0; x < selectorElements.length; x++) {
            if (selectorElements[x] !== elementsToMatch[x]) {
              return;
            }
          }
          identicalSelectors.push(currentSelector);
        }
      });
    }
    return identicalSelectors;
  }

  /**
   * Takes the given string and wraps it in a Javascript function
   *
   * @param {String} tagCode
   * @return {Function}
   */
  function evaluateTagCode(tagCode) {
    let locatorFn;
    try {
      // TODO: refactor this code to not use eval
      // eslint-disable-next-line no-eval
      locatorFn = eval(`(function($) {${tagCode}\n})`);
    } catch (error) {
      console.log(`Error evaluating code tag for code: ${locatorFn}`);
      return () => null;
    }

    return locatorFn;
  }

  /**
   * We require the body element to be present to set up event listeners, so wait until it's in the dom
   * before running initialization function
   *
   */
  const initializeInterval = window.setInterval(() => {
    if ($('body').length) {
      clearInterval(initializeInterval);
      initialize();
    }
  }, 5);

  /**
   * Traverse up the DOM until an element with a background image or the document element is hit
   *
   * @param {Element} element
   * @returns {Element|null}
   */
  function getParentElementWithBackgroundImage(element) {
    const parent = $(element).parent().length
      ? $(element).parent()[0]
      : document;

    if (parent === document) {
      return null;
    }

    if (window.getComputedStyle(parent).backgroundImage !== 'none') {
      return parent;
    }

    return getParentElementWithBackgroundImage(parent);
  }

  /**
   * Get the overlay class based on the selector type
   * @param {string} selectorType
   * @returns {string|null}
   */
  function getOverlayClass(selectorType) {
    switch (selectorType) {
      case EditorIframeEnums.IFrameSelectorTypes.TAG:
        return 'element-tag';
      case EditorIframeEnums.IFrameSelectorTypes.EVENT:
        return 'element-event';
      case EditorIframeEnums.IFrameSelectorTypes.EVENT_AND_TAG:
        return 'element-event element-tag';
      default:
        return null;
    }
  }
}

export default defaultExport = {
  bootstrapEditorClient,
  resetInner,
  run,
};
