/**
 * Enums specific to the p13_inner bundle
 */
import keyMirror from 'optly/utils/key_mirror';

export default {
  /**
   * Using the Optimizely Editor to modify non-standard or private elements should not be allowed
   * below are element identifiers for blacklisted elements, ids, and classes
   */
  IgnoredElementIdentifiers: {
    // Must be in UPPERCASE to match the node.tagName value
    tagNames: [
      'HEAD',
      'HTML',
      'LINK',
      'META',
      'OPTLYOVERLAY',
      'SCRIPT',
      'STYLE',
      'TITLE',
    ],
    // Regular Expressions for detecting private ids and classes (e.g. .optly-page-overlay). Must be a RegExp
    selectorRegExps: [
      new RegExp(/^[#.]optimizely-/),
      new RegExp(/^[#.]optly-/),
    ],
  },
  ChangeHandlerRequestQueueType: keyMirror({
    APPLY_CHANGES: null,
    EXECUTE_WITHOUT_CHANGES: null,
  }),
};
