export function isDesktopApp() {
  return !!__DESKTOP__ || !!window.optlyDesktop;
}

export function getInnerUrl() {
  return window.optlyDesktop && window.optlyDesktop.innerUrl;
}

export function getInnerUrlLegacy() {
  return window.optlyDesktop && window.optlyDesktop.innerUrlLegacy;
}

export function getAppVersion() {
  return window.optlyDesktop && window.optlyDesktop.version;
}

export function getAppPlatform() {
  return window.optlyDesktop && window.optlyDesktop.platform;
}

export default {
  isDesktopApp,
  getInnerUrl,
  getInnerUrlLegacy,
  getAppVersion,
  getAppPlatform,
};
