/*
 * WARNING: this file is currently being used from both the p13n_inner and preview_ui bundles.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 */

import isObject from 'lodash/isObject';
import Nuclear from 'nuclear-js';
import { toJS } from 'optly/immutable';
import updateArray from 'update-array';
import ReactDom from 'react-dom';

import parseQueryParams from 'optly/utils/parse_query_params';

let debug = __DEV__ && !window.mochaPhantomJS;

// check if the `optly_js_debug` query param is set
const queryParams = parseQueryParams(window.location.search);
if (queryParams.optly_js_debug === 'true') {
  debug = true;
} else if (queryParams.optly_js_debug === 'false') {
  debug = false;
}

const reactor = new Nuclear.Reactor({
  debug,
});

/**
 * Allows flux.dispatch calls within the `batchFn` to not trigger any
 * change observers until all dispatches are done
 * @param {Function} batchFn
 */
export const batch = reactor.batch.bind(reactor);

/**
 * Main dispatch method
 * Dispatches a flux action to all registered stores
 *
 * @param {string} type
 * @param {object} payload
 */

const oldDispatch = reactor.dispatch.bind(reactor);
reactor.dispatch = (actionType, payload) => {
  ReactDom.unstable_batchedUpdates(() => oldDispatch(actionType, payload));
};
export const { dispatch } = reactor;

/**
 * Attachs a store to the global reactor
 * @param {string} id
 * @param {Store} store
 */
export const registerStore = reactor.registerStore.bind(reactor);

/**
 * Attachs a map of storeId => store
 * @param {object} stores
 */
export const registerStores = reactor.registerStores.bind(reactor);

/**
 * Evaluates a KeyPath or Getter in context of the reactor state
 * @param {KeyPath|Getter} keyPathOrGetter
 * @return {*}
 */
export const evaluate = reactor.evaluate.bind(reactor);

/**
 * Evaluates a KeyPath or Getter in context of the reactor state and coerces
 * the value to a POJO
 * @param {KeyPath|Getter} keyPathOrGetter
 * @return {*}
 */
export const evaluateToJS = reactor.evaluateToJS.bind(reactor);

/**
 * Adds a change observer whenever a certain part of the reactor state changes
 *
 * 1. observe(handlerFn) - 1 argument, called anytime reactor.state changes
 * 2. observe(keyPath, handlerFn) same as above
 * 3. observe(getter, handlerFn) called whenever any getter dependencies change with
 *    the value of the getter
 *
 * Adds a change handler whenever certain deps change
 * If only one argument is passed invoked the handler whenever
 * the reactor state changes
 *
 * @param {KeyPath|Getter} getter
 * @param {function} handler
 * @return {function} unwatch function
 */
export const observe = reactor.observe.bind(reactor);

/**
 * Unbind listener for a particular vue value. Generally this should not be necessary,
 * however it can be used to deal with things like timing issues in component
 * instantiation/destruction
 *
 * @param {Vue} vm the Vue view model
 * @param {String} property Name of the property to unbind
 */
export function unbindVueValue(vm, property) {
  if (!vm.__fluxUnwatchFnsMap__ || !vm.__fluxUnwatchFnsMap__[property]) {
    throw new Error(
      'Could not unwatch non-existent bound view value:',
      property,
    );
  }
  vm.__fluxUnwatchFnsMap__[property]();
  delete vm.__fluxUnwatchFnsMap__[property];
}

/**
 * Binds a VM property to some value computed from one or more flux
 * stores.  This value will always stay in sync
 *
 * @param {Vue} vm the Vue view model
 * @param {Array.<string, Getter>} dataBindings
 */
export function bindVueValues(vm, dataBindings) {
  if (!vm.__fluxUnwatchFnsMap__) {
    vm.__fluxUnwatchFnsMap__ = {};

    vm.$on('unwatchFluxBindVueValues', () => {
      Object.keys(vm.__fluxUnwatchFnsMap__).forEach(key => {
        unbindVueValue(vm, key);
      });
    });
    vm.$on('hook:beforeDestroy', () => {
      vm.$emit('unwatchFluxBindVueValues');
    });
  }

  Object.keys(dataBindings).forEach(prop => {
    const getter = dataBindings[prop];
    if (vm.__fluxUnwatchFnsMap__[prop]) {
      if (__DEV__) {
        console.log(
          'Attempted to bind multiple getters to the same property:',
          prop,
          ', deleting',
        );
      }
      unbindVueValue(vm, prop);
    }

    // get the initial value
    try {
      vm.$set(prop, evaluateToJS(getter));
    } catch (e) {
      if (__DEV__) {
        console.error(e.stack); //eslint-disable-line
        throw new Error(
          `Cannot evaluate getter for bindVueValue with key = ${prop}`,
        );
      }
    }

    const unwatchFn = observe(getter, value => {
      const newValue = toJS(value);

      if (shouldUseArrayUpdater(newValue)) {
        updateArray(vm.$get(prop), newValue);
      } else {
        vm.$set(prop, newValue);
      }
    });
    vm.__fluxUnwatchFnsMap__[prop] = unwatchFn;
  });
}

/**
 * Resets the state of a reactor and returns back to initial state
 */
export const reset = reactor.reset.bind(reactor);

export const ReactMixin = reactor.ReactMixin;

/**
 * Helper function to determine if we should use the
 * update-array function to mutate objects in place
 * instead of replacing the entire array
 *
 * @param {*}
 * @return {Boolean}
 */
function shouldUseArrayUpdater(val) {
  // must be array
  if (!Array.isArray(val)) {
    return false;
  }
  // must be array of length > 0
  if (val.length === 0) {
    return false;
  }

  // check that there are no duplicate entries with same id
  let useArrayUpdater = true;
  const idMap = {};
  for (let i = 0; i < val.length; i++) {
    const item = val[i];
    if (!isObject(item)) {
      // item must be 'object'
      return false;
    }
    if (!item.id) {
      useArrayUpdater = false;
      break;
    }
    if (idMap[item.id]) {
      // is duplicate
      useArrayUpdater = false;
      break;
    }
    idMap[item.id] = true;
  }
  return useArrayUpdater;
}

export default {
  batch,
  dispatch,
  registerStore,
  registerStores,
  evaluate,
  evaluateToJS,
  observe,
  bindVueValues,
  unbindVueValue,
  reset,
  ReactMixin,
};
