const Nuclear = require('nuclear-js');

const { toImmutable } = require('optly/immutable');

const dateUtils = require('optly/utils/date');

const actionTypes = require('../action_types');
const enums = require('../enums');

/**
 * iframeStore
 */
module.exports = Nuclear.Store({
  /**
   * Initial state of store when registered with NuclearJS Flux system
   * default returns an Immutable.Map
   * Note: must return an immutable value
   */
  getInitialState() {
    return toImmutable({});
  },

  initialize() {
    this.on(actionTypes.P13N_EDITOR_IFRAME_ADD_IFRAME, addIFrame);
    this.on(actionTypes.P13N_EDITOR_IFRAME_EVAL_COMPLETE, evalCompleted);
    this.on(actionTypes.P13N_EDITOR_IFRAME_HIGHLIGHT_ELEMENT, highlightElement);
    this.on(
      actionTypes.P13N_EDITOR_IFRAME_INCREMENT_ALERT_COUNT,
      incrementCustomCodeAlertCount,
    );
    this.on(actionTypes.P13N_EDITOR_IFRAME_LOG, log);
    this.on(actionTypes.P13N_EDITOR_IFRAME_REMOVE_IFRAME, removeIFrame);
    this.on(actionTypes.P13N_EDITOR_IFRAME_RESET, reset);
    this.on(actionTypes.P13N_EDITOR_IFRAME_SET_EDITOR_TYPE, setEditorType);
    this.on(actionTypes.P13N_EDITOR_IFRAME_SET_FRAME_TYPE, setFrameType);
    this.on(
      actionTypes.P13N_EDITOR_IFRAME_SET_IS_LANDSCAPE_MODE,
      setIsLandscapeMode,
    );
    this.on(actionTypes.P13N_EDITOR_IFRAME_SET_MODE, setMode);
    this.on(actionTypes.P13N_EDITOR_IFRAME_SET_READY_STATE, setReadyState);
    this.on(
      actionTypes.P13N_EDITOR_IFRAME_SET_SELECTED_DEVICE,
      setSelectedDevice,
    );
    this.on(actionTypes.P13N_EDITOR_IFRAME_SET_URL, setUrl);
    this.on(actionTypes.P13N_EDITOR_IFRAME_SET_WIDTH, setWidth);
    this.on(
      actionTypes.P13N_EDITOR_IFRAME_UNHIGHLIGHT_ALL_ELEMENTS,
      unhighlightAllElements,
    );
    this.on(
      actionTypes.P13N_EDITOR_IFRAME_UNHIGHLIGHT_ELEMENT,
      unhighlightElement,
    );
  },
});

/**
 * Handler
 *
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.id id to index this under
 * @param {object} payload.component iframe component handle
 * @returns {Immutable.Map}
 */
function addIFrame(state, payload) {
  let iframeState = toImmutable({
    id: payload.id,
    log: [],
    highlightedElements: [],
    mode: enums.IFrameModeTypes.STANDARD,
    loadStatus: enums.IFrameLoadStatuses.INITIAL,
    protocolType: null,
    loadDuration: null,
    width: '100%',
    selectedDevice: enums.Sizes.full,
    isLandscapeMode: false,
    popout: false,
    url: payload.url,
    frameType: enums.FrameTypes.IFRAME,
    editorType: payload.editorType,
    customCodeAlertsShown: 0,
    evalError: null,
    created: dateUtils.getNow(),
  });
  iframeState = iframeState.set('component', payload.component);
  return state.set(payload.id, iframeState);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.iframeId
 * @param {Number} payload.width
 * @returns {Immutable.Map}
 */
function setWidth(state, payload) {
  return state.setIn([payload.iframeId, 'width'], payload.width);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.iframeId
 * @param {Object} payload.selectedDevice
 * @returns {Immutable.Map}
 */
function setSelectedDevice(state, payload) {
  return state.setIn(
    [payload.iframeId, 'selectedDevice'],
    payload.selectedDevice,
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.iframeId
 * @param {Boolean} payload.isLandscapeMode
 * @returns {Immutable.Map}
 */
function setIsLandscapeMode(state, payload) {
  return state.setIn(
    [payload.iframeId, 'isLandscapeMode'],
    payload.isLandscapeMode,
  );
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.id iframe id
 * @param {String} payload.category 'emit' or 'write'
 * @param {Object} payload.message '
 * @param {String} payload.message.type
 * @param {Object?} payload.message.payload
 * @returns {Immutable.Map}
 */
function log(state, payload) {
  if (!state.has(payload.id)) {
    return state;
  }

  return state.updateIn([payload.id, 'log'], log => {
    if (!log || !log.push) {
      return log;
    }

    const entry = toImmutable(payload).remove('id');
    return log.push(entry);
  });
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.id
 * @returns {Immutable.Map}
 */
function removeIFrame(state, payload) {
  return state.delete(payload.id);
}

/**
 * @param {Immutable.Map} state
 * @param {object} payload
 * @param {string} payload.id
 * @param {enums.IFrameModeTypes} payload.enabled
 * @returns {Immutable.Map}
 *
 */
function setMode(state, payload) {
  return state.setIn([payload.id, 'mode'], payload.mode);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.selector
 * @param {number} payload.type
 * @returns {Immutable.Map}
 */
function highlightElement(state, payload) {
  return state.updateIn([payload.id, 'highlightedElements'], highlighted => {
    const index = highlighted.findIndex(
      element =>
        element.get('dataOptlyId') === payload.dataOptlyId &&
        element.get('selector') === payload.selector &&
        element.get('type') === payload.type,
    );
    if (index === -1) {
      return highlighted.push(
        toImmutable({
          dataOptlyId: payload.dataOptlyId,
          selector: payload.selector,
          type: payload.type,
        }),
      );
    }
    return highlighted;
  });
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.type
 * @returns {Immutable.Map}
 */
function setEditorType(state, payload) {
  return state.setIn([payload.id, 'editorType'], payload.type);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.type
 * @returns {Immutable.Map}
 */
function setFrameType(state, payload) {
  return state.setIn([payload.id, 'frameType'], payload.type);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.loadStatus
 * @param {String} payload.protocolType
 * @returns {Immutable.Map}
 */
function setReadyState(state, payload) {
  return state
    .setIn([payload.id, 'loadStatus'], payload.loadStatus)
    .setIn([payload.id, 'protocolType'], payload.protocolType)
    .setIn([payload.id, 'loadDuration'], payload.loadDuration)
    .setIn([payload.id, 'customCodeAlertsShown'], 0);
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @param {number} payload.selector
 * @param {number} payload.type
 * @returns {Immutable.Map}
 */
function unhighlightElement(state, payload) {
  return state.updateIn([payload.id, 'highlightedElements'], highlighted => {
    const index = highlighted.findIndex(
      element =>
        element.get('dataOptlyId') === payload.dataOptlyId &&
        element.get('selector') === payload.selector &&
        element.get('type') === payload.type,
    );
    if (index > -1) {
      return highlighted.delete(index);
    }
    return highlighted;
  });
}

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {number} payload.id
 * @returns {Immutable.Map}
 */
function unhighlightAllElements(state, payload) {
  return state.setIn([payload.id, 'highlightedElements'], toImmutable([]));
}

function reset(state) {
  return toImmutable({});
}

function incrementCustomCodeAlertCount(state, payload) {
  return state.setIn(
    [payload.id, 'customCodeAlertsShown'],
    state.getIn([payload.id, 'customCodeAlertsShown']) + 1,
  );
}

/**
 * Set the url for the iframe associated with the payload id
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.id
 * @param {String} payload.url
 */
function setUrl(state, payload) {
  return state.setIn([payload.id, 'url'], payload.url);
}

/**
 * Set the evalError for the iframe associated with the payload id
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {Number} payload.id
 * @param {String?} payload.error
 */
function evalCompleted(state, payload) {
  return state.setIn([payload.id, 'evalError'], payload.error);
}
