/**
 * Editor Iframe Module Constants
 */
const keyMirror = require('optly/utils/key_mirror');
const tr = require('optly/translate');

module.exports = {
  /**
   * Messages to use for the 3 major browsers
   */
  BrowserBeforeUnloadMessages: {
    CHROME: tr('Stay on this Page'),
    NON_CHROME: tr('Cancel'),
  },

  /**
   * load status types the iframe component uses to track innie/iframe loading
   */
  InnerLoadStatus: keyMirror({
    READY: null, // DomReady
    FAILED: null, // Hello timed out
    LOADED: null, // Waiting for DomReady
    WAITING: null, // Waiting for hello
  }),

  /**
   * Message types the innie script and iframe component can use to communicate
   */
  IFrameMessageTypes: keyMirror({
    APPLY_CHANGES: null,
    APPLY_CHANGES_AND_FETCH_ELEMENT_INFO: null,
    CLICK: null,
    DESTROY: null,
    ELEMENT_INFO: null,
    EVAL: null,
    EVAL_ERROR: null,
    TIMEDOUT: null,
    FAILED: null,
    FETCH_ELEMENT_INFO: null,
    FETCH_RELATED_ELEMENT_SELECTORS: null,
    FETCH_TAG_VALUE: null,
    HELLO: null,
    HIGHLIGHT_ELEMENT: null,
    HOVER: null,
    KEY_UP: null,
    LOAD_URL: null,
    CONTENT_READY: null,
    POP_IN: null,
    READY: null,
    REGISTER: null,
    RESOLVE_DEFERRED: null,
    RELOAD: null,
    SECURITY_EXCEPTION: null,
    SET_CHANGE_APPLIER_RESULT: null,
    SET_ELEMENT_INFO: null, // TODO delete
    SET_MODE: null,
    SET_TAG_VALUE: null, // TODO delete
    TEST_SELECTOR_EQUALITY: null,
    UNHIGHLIGHT_ELEMENT: null,
  }),

  IFrameSelectorTypes: keyMirror({
    EVENT: null,
    EVENT_AND_TAG: null,
    TAG: null,
  }),

  /**
   * Protocol types for editor iframe loading.
   */
  ProtocolTypes: keyMirror({
    HTTP: null,
    HTTPS: null,
    PROXY: null,
  }),

  /**
   * Protocol types for preview URLs.
   */
  PreviewProtocolTypes: keyMirror({
    PREVIEW_PROXY: null,
  }),

  /**
   * Types user interaction for the current frame.
   */
  IFrameModeTypes: keyMirror({
    STANDARD: null,
    INTERACTIVE: null,
    NO_HOVER: null,
  }),

  /**
   * Statuses for the overall loading of an editor_iframe component.
   */
  IFrameLoadStatuses: keyMirror({
    INITIAL: null,
    LOADING: null,
    READY: null,
    FAILED: null,
    CANCELED: null, // User canceled
  }),

  /**
   * The types of frames used in the editor_iframe component.
   */
  FrameTypes: keyMirror({
    IFRAME: null,
    POPOUT: null,
    WEBVIEW: null,
  }),

  /**
   * The various types of editors that can be used with the editor_iframe module.
   */
  EditorTypes: keyMirror({
    EXPERIMENT_EDITOR: null,
    EXPERIMENT_EDITOR_READ_ONLY: null,
    VIEW_EDITOR: null,
  }),

  /**
   * Availible `width` options for the editor iframe.
   */
  Sizes: {
    custom: {
      key: 'custom',
      label: tr('Custom Width'),
      width: null,
      height: '100%',
      userAgent: null,
      icon: 'arrows-left-right',
    },
    full: {
      key: 'full',
      label: tr('Full Width'),
      width: '100%',
      height: '100%',
      userAgent: null,
      icon: 'arrows-left-right',
      testSection: 'responsive-presets-full',
    },
    desktop: {
      key: 'desktop',
      label: tr('Desktop'),
      width: 980,
      height: '100%',
      userAgent: null,
      icon: 'display',
      testSection: 'responsive-presets-desktop',
    },
    tablet: {
      key: 'tablet',
      label: tr('Tablet'),
      width: 768,
      height: '100%',
      userAgent: null,
      icon: 'tablet',
      testSection: 'responsive-presets-tablet',
    },
    mobile: {
      key: 'mobile',
      label: tr('Mobile'),
      width: 320,
      height: '100%',
      userAgent: null,
      icon: 'mobile',
      testSection: 'responsive-presets-mobile',
    },
  },

  /**
   * Information for device emulation
   */
  Devices: {
    iPhone6: {
      key: 'iPhone6',
      label: 'iPhone 6',
      width: 375,
      height: 667,
      userAgent:
        'Mozilla/5.0 (iPhone; CPU iPhone OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25',
      icon: 'mobile',
      testSection: 'emulation-presets-iPhone6',
    },
    iPad: {
      key: 'iPad',
      label: 'iPad',
      width: 768,
      height: 1024,
      userAgent:
        'Mozilla/5.0 (iPad; CPU OS 6_0 like Mac OS X) AppleWebKit/536.26 (KHTML, like Gecko) Version/6.0 Mobile/10A5376e Safari/8536.25',
      icon: 'tablet',
      testSection: 'emulation-presets-iPad',
    },
    galaxyS5: {
      key: 'GalaxyS5',
      label: 'Galaxy S5',
      width: 360,
      height: 640,
      userAgent:
        'Mozilla/5.0 (Linux; Android 4.4.2; en-us; SAMSUNG SM-G900T Build/KOT49H) AppleWebKit/537.36 (KHTML, like Gecko) Version/1.6 Chrome/28.0.1500.94 Mobile Safari/537.36',
      icon: 'mobile',
      testSection: 'emulation-presets-galaxyS5',
    },
  },

  PresetsTabs: keyMirror({
    RESIZE: null,
    EMULATE: null,
  }),

  /**
   * Error codes in Chromium used by Desktop App
   */
  ChromiumErrorCodes: {
    NAME_NOT_RESOLVED: -105,
  },
};
