const keyMirror = require('optly/utils/key_mirror');

module.exports = keyMirror({
  P13N_EDITOR_IFRAME_ADD_IFRAME: null,
  P13N_EDITOR_IFRAME_EVAL_COMPLETE: null,
  P13N_EDITOR_IFRAME_HIGHLIGHT_ELEMENT: null,
  P13N_EDITOR_IFRAME_INCREMENT_ALERT_COUNT: null,
  P13N_EDITOR_IFRAME_LOG: null,
  P13N_EDITOR_IFRAME_REGISTER_PROTOCOL_FOR_URL: null,
  P13N_EDITOR_IFRAME_REMOVE_IFRAME: null,
  P13N_EDITOR_IFRAME_RESET: null,
  P13N_EDITOR_IFRAME_SET_EDITOR_TYPE: null,
  P13N_EDITOR_IFRAME_SET_FRAME_TYPE: null,
  P13N_EDITOR_IFRAME_SET_IS_LANDSCAPE_MODE: null,
  P13N_EDITOR_IFRAME_SET_MODE: null,
  P13N_EDITOR_IFRAME_SET_READY_STATE: null,
  P13N_EDITOR_IFRAME_SET_SELECTED_DEVICE: null,
  P13N_EDITOR_IFRAME_SET_URL: null,
  P13N_EDITOR_IFRAME_SET_WIDTH: null,
  P13N_EDITOR_IFRAME_UNHIGHLIGHT_ALL_ELEMENTS: null,
  P13N_EDITOR_IFRAME_UNHIGHLIGHT_ELEMENT: null,
  P13N_EDITOR_IFRAME_UNREGISTER_PROTOCOL_FOR_URL: null,
  P13N_EDITOR_SET_LOGGING_ENABLED: null,
});
