/**
 * legacy optly.domain re-written in CommonJS
 */

import config from 'atomic-config';

let domain;
/**
 * Blacklist Optimizely hosts serving content that we don't control
 *   -  our own proxies (which can load arbitrary malicious sites)
 *   -  our CDN (which can host arbitrary malicious JS for anyone
 *        with an optimizely.com account)
 */
const HOST_BLACK_LIST = [
  /.*?\.?optimizelyedit\.(com|test)/,
  /.*\.?optimizelypreview\.(com|test)/,
  /(edit|preview)(-local)?(-hrd)?\.optimizely\.(com|test)/,
  /optimizelyedit(-hrd)?\.appspot\.com/,
  /cdn[^.]*\.optimizely\.com/,
];

/**
 * Blacklist paths that can load uncontrolled and therefore malicious
 * content
 *   -  path that loads arbitrary client.js files
 */
const PATH_BLACK_LIST = [
  /^\/?api\/client/, // IE does not have leading / for location.pathname
];

/**
 * Check if URL serves content under Optimizely control.
 * 1. The URL's domain and scheme must match one provided in env.ORIGIN_WHITELIST
 * 2. The URL's domain must not be on the HOST_BLACK_LIST, just in case we accidentally included a blacklisted origin on the whitelist.
 * 3. The URL's path must not be on the PATH_BLACK_LIST (e.g. that contain user generated content and therefore unsafe)
 *
 * @param {String} url
 */
export function check(url) {
  const location = domain.getLocation(url);

  for (let i = 0; i < PATH_BLACK_LIST.length; i++) {
    if (PATH_BLACK_LIST[i].test(location.pathname)) {
      return false;
    }
  }

  for (let j = 0; j < HOST_BLACK_LIST.length; j++) {
    if (HOST_BLACK_LIST[j].test(location.hostname)) {
      return false;
    }
  }

  let WHITE_LIST = config.get('env.ORIGIN_WHITELIST') || [];

  /* In v1 editor, the whitelist is in window.optlyConfig.env.ORIGIN_WHITELIST */
  if (
    WHITE_LIST.length === 0 &&
    window.optlyConfig &&
    window.optlyConfig.env &&
    window.optlyConfig.env.ORIGIN_WHITELIST
  ) {
    WHITE_LIST = window.optlyConfig.env.ORIGIN_WHITELIST;
  }

  for (let k = 0; k < WHITE_LIST.length; k++) {
    if (WHITE_LIST[k] === `${location.protocol}//${location.hostname}`) {
      return true;
    }
  }

  return false;
}

/**
 * @param {String} url
 */
export function getLocation(url) {
  const anchor = document.createElement('a');
  anchor.href = url;
  return anchor;
}

/**
 * Returns 'test' if local dev and 'com' otherwise
 * @returns {String}
 */
export function getSuffix() {
  if (__DEV__) {
    return 'test';
  }
  return 'com';
}

export default domain = {
  check,
  getLocation,
  getSuffix,
};
