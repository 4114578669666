/*
 * WARNING: this file is currently being used from both the p13n_inner and preview_ui bundles.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.
 */

/* eslint-disable import/no-commonjs */

import $ from 'jquery';
import sprintf from 'sprintf';
import queryHelpers from 'optly/utils/query_selector';

import constants from './constants';
import enums from './enums';

import SelectedElementTagIcon from '/static/img/p13n/selected-element-tag-16.svg';

import SelectedElementEventIcon from '/static/img/p13n/selected-element-event-16.svg';

import SeleectedElementTagAndEventIcon from '/static/img/p13n/selected-element-tag-and-event-16.svg';

// Mapping of hover types to class names
const highlightTypeClass = {};
highlightTypeClass[enums.IFrameHighlightTypes.HOVERED] = 'optly-hover';
highlightTypeClass[enums.IFrameHighlightTypes.SELECTED] = 'optly-selected';
highlightTypeClass[enums.IFrameHighlightTypes.LIVE] = 'optly-live';
highlightTypeClass[enums.IFrameHighlightTypes.STAGED] = 'optly-staged';
highlightTypeClass[enums.IFrameHighlightTypes.DRAFT] = 'optly-draft';

let $body;
let $html;
let tagEventIconStylesheetAdded = false;

const HIGHLIGHTED_ELEMENT_SELECTOR = 'div.optly-cursor';

/**
 * Append the highlighter stylesheet to the page.
 * Note: Webpack shouldn't hoist and therefore invoke this require
 * (which appends the stylesheet via a css loader) until this function is invoked.
 */
function appendStlyesheet() {
  // Append the required stylesheet to the page.
  require('./stylesheet.css'); // eslint-disable-line
}

/**
 * set element css position
 * @param {jquery} $el
 * @param {number} x horizontal position
 * @param {number} y vertical position
 */
function setElementPosition($el, x, y) {
  $el.css({
    top: y,
    left: x,
  });
}

/**
 * set element css size
 * @param {jquery} $el
 * @param {number} w width
 * @param {number} h height
 */
function setElementSize($el, w, h) {
  $el.css({
    width: w,
    height: h,
  });
}

/**
 * Create and return an overlay element with the specified attributes.
 * @param {object} options
 * @param {string} options.label
 * @param {string} options.dataOptlyId
 * @param {string} options.selector
 * @param {string} options.overlayClass The class being used for the overlay.
 * @param {string} options.type The type of highlighting we want to apply(HOVERED, SELECTED, ETC)
 * @returns {*|HTMLElement}
 */
function generateOverlayElement(options) {
  const config = {
    class: `optly-cursor ${highlightTypeClass[options.type]}`,
    'data-highlight-type': options.type,
  };

  if (options.selector) {
    config['data-highlight-selector'] = options.selector;
  }

  if (options.dataOptlyId) {
    config['data-highlight-optlyid'] = options.dataOptlyId;
  }

  if (options.label) {
    config['cursor-label'] = options.label.toLowerCase();
  }

  if (options.overlayClass) {
    config['data-highlight-overlay-class'] = options.overlayClass;
  }

  return $('<div></div>', config);
}

/**
 * adds a highlighter border with specified style class to the given element selector
 * @param {object} options
 * @param {String} options.type the highlight type
 * @param {object} options.dataOptlyId The id portion of the data-optly-<id> attribute of an element.
 * @param {object} options.selector selector or DOM node
 * @param {String} options.label optional string to show as the cursor label
 * @param {String} options.overlayClass class being used for the overaly.
 */
function highlight(options) {
  let selectorToUse = options.selector || '';
  if (options.dataOptlyId) {
    selectorToUse = `[${constants.CHANGE_ID_ATTRIBUTE_PREFIX}${options.dataOptlyId}]`;
  }
  const elements = queryHelpers.querySelectorAll(selectorToUse);
  [].forEach.call(elements, element => {
    const $overlay = generateOverlayElement(options)
      .hide()
      .appendTo(document.body);
    $overlay.get(0).optimizelyElementToHighlight = element;
    setOverlayPropertiesForElement($overlay);
    if (options.overlayClass) {
      $overlay.toggleClass(options.overlayClass, true);
      addLabelIconsStylesheet(options.hostUrl);
    }

    // if the element is display:none, we don't want to show the overlay. However,
    // we do want the overlay in the dom so that in the case that we are toggling
    // the "remove" change type it will correctly rerender
    if (element.style.display !== 'none') {
      $overlay.show();
    }
  });
}

/**
 * Given an overlay element, set its position and size based on the element that is being highlighted.
 * @param $overlay
 */
function setOverlayPropertiesForElement($overlay) {
  // NB. getElementBounds() seems to incorrectly return a width and height
  const bounds = getElementBounds($overlay.get(0).optimizelyElementToHighlight);
  // A 1 pixel offset is needed to align the overlay correctly on the top left corner of the element (caused by the iframe).
  const leftSpacing = getElementSpacingOffset('left') + 1;
  const topSpacing = getElementSpacingOffset('top') + 1;
  setElementPosition(
    $overlay,
    bounds.left - leftSpacing,
    bounds.top - topSpacing,
  );
  setElementSize($overlay, bounds.width, bounds.height);
}

/**
 * Calculate the total offset of an element given a direction.
 * This is needed when the iframe's HTML or BODY elements have
 * non-zero margin/padding/border values that aren't considered by $().offset
 * @param selector
 * @param direction
 */
function getElementSpacingOffset(direction) {
  $html = $html || $('html');
  $body = $body || $('body');
  const htmlPadding = parseInt($html.css(`padding-${direction}`), 10);
  const htmlMargin = parseInt($html.css(`margin-${direction}`), 10);

  const htmlBorder = parseInt($html.css(`border-${direction}-width`), 10);
  const bodyBorder = parseInt($body.css(`border-${direction}-width`), 10);

  let borderOffset = 0;

  const scrollSpacing = direction === 'top' ? window.scrollY : window.scrollX;

  if (htmlBorder > 0 && bodyBorder > 0) {
    borderOffset = htmlBorder + bodyBorder;
  }
  return parseInt(htmlPadding + htmlMargin + borderOffset + scrollSpacing, 10);
}

/**
 * @param {Object} element
 */
function getElementBounds(element) {
  const $element = $(element);
  const offset = $element.offset();
  const { left } = offset;
  const { top } = offset;
  // Can't just call .outerWidth() and .outerHeight().  For some reason, these
  // calls end up going to the customer's version of jQuery, which in some
  // cases returns jQuery objects instead of integers when called without
  // arguments.
  const width = $element.outerWidth(false);
  const height = $element.outerHeight(false);

  return {
    bottom: top + height,
    left,
    right: left + width,
    top,
    width,
    height,
  };
}

/**
 * removes a highlighter border with specified style class to the given element selector
 * @param {String} type the highlight type
 * @param {String=} selector
 * @param {String=} dataOptlyId
 */
function unhighlight(options) {
  const overlaySelector = `[data-highlight-type="${options.type}"]`;
  const nodeList = queryHelpers.querySelectorAll(overlaySelector);
  if (options.dataOptlyId) {
    [].forEach.call(nodeList, node => {
      if (node.getAttribute('data-highlight-optlyid') === options.dataOptlyId) {
        safelyRemoveNode(node);
      }
    });
  }
  if (options.selector) {
    [].forEach.call(nodeList, node => {
      if (node.getAttribute('data-highlight-selector') === options.selector) {
        safelyRemoveNode(node);
      }
    });
  }
  if (!options.selector && !options.dataOptlyId) {
    [].forEach.call(nodeList, safelyRemoveNode);
  }
}

/**
 * Removing a child node from the DOM does not immediately remove it from memory,
 * making a subsequent call to remove it fail on accessing the parentNode.
 * This function safely invokes removeChild only if it's parent is defined.
 * @param node
 */
function safelyRemoveNode(node) {
  if (node && node.parentNode) {
    node.parentNode.removeChild(node);
  }
}

/**
 * Redraws all overlays
 */
function rerender() {
  const overlayElements = queryHelpers.querySelectorAll(
    HIGHLIGHTED_ELEMENT_SELECTOR,
  );
  [].forEach.call(overlayElements, overlayElement =>
    setOverlayPropertiesForElement($(overlayElement)),
  );
}

/**
 * Show or hide all overlays (used when toggling interactive mode) by appending/removing
 * a style tag to the body that shows/hides all overlays.
 * @param {Boolean} shown - Whether or not element overlays should be displayed.
 */
function showElementHighlighters(shown) {
  if (shown) {
    $(HIGHLIGHTED_ELEMENT_SELECTOR).show();
  } else {
    $(HIGHLIGHTED_ELEMENT_SELECTOR).hide();
  }
}

/**
 * Add an extra stylesheet that contains the icons for tag and event selectors
 * We need to add this dynamically because we need to specify the host url from which to pull the assets from
 * @param {string} hostUrl - The host url to use for the SVG assets.
 */
function addLabelIconsStylesheet(hostUrl) {
  if (!tagEventIconStylesheetAdded) {
    const TAG_LABEL_ICON_PATH = hostUrl + SelectedElementTagIcon;
    const EVENT_LABEL_ICON_PATH = hostUrl + SelectedElementEventIcon;
    const TAG_AND_EVENT_LABEL_ICON_PATH =
      hostUrl + SeleectedElementTagAndEventIcon;
    $(
      `<style id="optly-tag-event-icons">${sprintf(
        '.optly-cursor.element-tag:not(.optly-hover):after { content: "\\00a0"; background-image: url(%s); }',
        TAG_LABEL_ICON_PATH,
      )}${sprintf(
        '.optly-cursor.element-event:not(.optly-hover):after { content: "\\00a0"; background-image: url(%s); }',
        EVENT_LABEL_ICON_PATH,
      )}${sprintf(
        '.optly-cursor.element-event.element-tag:not(.optly-hover):after { content: "\\00a0\\00a0"; background-image: url(%s); }',
        TAG_AND_EVENT_LABEL_ICON_PATH,
      )}</style>`,
    ).appendTo('head');
    tagEventIconStylesheetAdded = true;
  }
}

export {
  appendStlyesheet,
  highlight,
  unhighlight,
  highlightTypeClass,
  rerender,
  showElementHighlighters,
  enums,
};

export default {
  appendStlyesheet,
  highlight,
  unhighlight,
  highlightTypeClass,
  rerender,
  showElementHighlighters,
  enums,
};
