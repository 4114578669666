/*
 * NOTE: this bundle entrypoint is exposed on customer websites and we are intentionally not applying es6 polyfills.
 * Currently, this means we SHOULD NOT use any ES7+ syntax/functions and need to be very careful
 * about the dependencies referenced in this module.  Ideally, no modules should be imported from within another bundle's
 * source files.  All required dependencies should also avoid ES6+ functions to avoid issues with the lack of polyfills.
 */

// LOAD CONFIG ON THE PAGE INTO THE CONFIG MODULE
import config from 'atomic-config';

import inner from './inner';

if (window.optlyConfig) {
  config.set(window.optlyConfig);
}

const webPublicPath = config.get('env.WEBPACK_PUBLIC_PATH');
// For the desktop app, the hostname is specified in env.ORIGIN_WHITELIST[0]. This isnt ideal, but must be used at least in
// the interim to correctly determine the hostname at runtime.
// See https://github.com/optimizely/optimizely-desktop/blob/89b878242a9b90bc54887704a28f9c0d9b298929/dev/src/p13n/innerBase.js#L45
const desktopAppPublicPath = config.get('env.ORIGIN_WHITELIST') && `${config.get('env.ORIGIN_WHITELIST')[0]}/dist/js/`;
const defaultProductionPublicPath = 'https://app.optimizely.com/dist/js/';

const preferredPublicPath = webPublicPath || desktopAppPublicPath || defaultProductionPublicPath;

if (__DEV__) {
  console.log('[P13N INNER MAIN] Setting runtime bundle public path to ', preferredPublicPath); // eslint-disable-line
}

// Although we don't intentionally use dynamic imports in this bundle, set the public path
// just in case a shared resource implements a dynamic import that p13n_inner requires.
__webpack_public_path__ = preferredPublicPath; // eslint-disable-line

inner.run();
