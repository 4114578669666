/**
 * Returns window.performance.now() if available, otherwise returns Date.now()
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Performance/now
 * @return {Number}
 */
exports.getNow = () => {
  if (window.performance && window.performance.now) {
    return window.performance.now();
  }
  return Date.now();
};
