import filter from 'lodash/filter';

import queryHelpers from 'optly/utils/query_selector';

import { generateSelector as selectorator } from './selectorator';
import enums from './enums';

/**
 * Filter function to remove specific elements that match specified tag names
 * @param {node} elementNode
 * @returns {boolean} - returns false if the value should be filtered
 */
function filterElementsByTagNameFn(elementNode) {
  return !enums.IgnoredElementIdentifiers.tagNames.some(tagName => elementNode.tagName === tagName);
}

/**
 * Filter function to remove selectors that match specified substrings
 * @param {string} selector
 * @returns {boolean} - returns false if the value should be filtered
 */
function filterSelectorsByRegExpFn(selector) {
  return !enums.IgnoredElementIdentifiers.selectorRegExps.some(selectorRegExp => selectorRegExp.test(selector));
}

/**
 * Get all of an element's parent elements up the DOM tree
 * @param  {Node} element
 * @return {Array} The parent elements
 */
function getParents(element) {
  // Return an empty Array if the element is null or undefined
  if (!element) {
    return [];
  }
  // Initialize element variable for iteration and setup parents array
  let elem = element;
  const parents = [];
  // Get matching parent elements with node type 1 (ELEMENT_NODE)
  while (elem) {
    elem = elem.parentElement;
    if (elem && elem.nodeType === Node.ELEMENT_NODE) {
      parents.push(elem);
    }
  }
  return parents;
}

/**
 * Get direct child and parent info for the first element matching the provided selector
 * @param {string} selector
 * @return {Object}
 */
export function getRelatedElementSelectors(selector) {
  // Get the first matching element for the provided selector
  const element = queryHelpers.querySelector(selector);

  // If an element is not found for a given selector, return right away
  if (!element) {
    return {
      childSelectors: [],
      parentSelectors: [],
    };
  }

  // Filter out tags that shouldn't be returned, make a list of element selectors, then similarly filter those selectors
  const childSelectors = filter(element.children, filterElementsByTagNameFn)
    .map(child => selectorator(child))
    .filter(filterSelectorsByRegExpFn);

  // Compute a reversed list of the element's parent elements up the DOM tree
  const parentElementsDescending = getParents(element).reverse();
  const parentSelectors = filter(parentElementsDescending, filterElementsByTagNameFn)
    .map(parent => selectorator(parent))
    .filter(filterSelectorsByRegExpFn);

  return {
    childSelectors,
    parentSelectors,
  };
}

export default {
  getRelatedElementSelectors,
};
