import assign from 'lodash/assign';
import compact from 'lodash/compact';
import flattenDeep from 'lodash/flattenDeep';

import queue from 'optly/utils/queue';

import LayerExperimentEnums from 'optly/modules/entity/layer_experiment/enums';
import EditorIframeEnums from 'bundles/p13n/modules/editor_iframe/enums';

import Deferred from './deferred';
import enums from './enums';
import writeChannel from './write_channel';

// Change applier that is applying/applied/undoing the singleton changeset
let currentChangeApplier = null;

// Queue to hold all the inbound events that need to be processed.
const changeQueue = queue.create();

// Flag to determine if the queue is already processing so that we don't kick off another process
let queueIsProcessing = false;

/**
 * Generic method for adding a request to the change handler and kick off processing of the queue
 *
 * @param {string} type
 * @param {object} payload
 * @returns {Deferred}
 */
export function pushChangeHandlerRequest(type, payload) {
  const requestDeferred = new Deferred();

  changeQueue.add({
    type,
    payload,
    deferred: requestDeferred,
  });

  processQueue();

  return requestDeferred;
}

/**
 * Helper function to execute a request to apply changes specified by the changeSet in the payload.
 * Applying changes undoes everything and then applies the current changeset.
 * If there are multiple unprocessed requests to apply changes there is no need
 * to execute all of them. Reject everything except for the last one.
 *
 * @param {object} data - item from the queue with type 'apply changes'
 */
function applyChanges(data) {
  const currentDeferred = data.deferred;

  // If next change is of the same type, reject the current one
  if (changeQueue.peek() && changeQueue.peek().type === data.type) {
    currentDeferred.reject();
  } else {
    const changeSet = data.payload.changeSet;

    if (currentChangeApplier) {
      console.debug('[P13N INNER][CHANGE HANDLER] Undoing current change applier.');  // eslint-disable-line
      currentChangeApplier.undo().then(() => {
        executeChangeSet(changeSet, currentDeferred);
      });
    } else {
      executeChangeSet(changeSet, currentDeferred);
    }
  }

  return currentDeferred;
}

/**
 * Helper function to process requests which need to be executed on the page without any changes applied.
 * Will undo all of the current changes, execute the function passed to it, redo the changes
 * and return the result of the function call with the deferred response.
 *
 * @param {object} data - item from the queue with type 'execute without changes'
 */
function invokeWithoutChanges(data) {
  const functionToExecute = data.payload.functionToExecute;
  const partialUndoChangeId = data.payload.partialUndoChangeId;
  const currentDeferred = data.deferred;

  if (currentChangeApplier) {
    console.debug('[P13N INNER][CHANGE HANDLER] Undoing current change applier.');  // eslint-disable-line
    currentChangeApplier.undo(partialUndoChangeId).then(() => {
      const result = functionToExecute();
      currentChangeApplier.apply().then(() => currentDeferred.resolve(result));
    });
  } else {
    currentDeferred.resolve(functionToExecute());
  }
}

/**
 * Apply a changeset
 * @param {array} changeSet
 * @param {deferred} resolver
 * @returns {undefined}
 */
function executeChangeSet(changeSet, resolver) {
  // Format custom code appropriately for changeset application
  changeSet = transformChangeSet(changeSet);
  const changeApplier = window.optimizely.get('client-change-applier');
  const $ = window.optimizely.get('jquery');
  let EventEmitter;

  // TODO: remove when client 0.13.5 goes out
  try {
    EventEmitter = window.optimizely.get('event_emitter');
  } catch (e) {
    // no-op
  }

  console.debug('[P13N INNER][CHANGE HANDLER] Applying change with new change applier.');  // eslint-disable-line
  // Instantiate a new change applier
  currentChangeApplier = changeApplier.create(changeSet, '', [$], EventEmitter);
  currentChangeApplier.apply().then(
    (result) => {
      console.debug('[P13N INNER][CHANGE HANDLER] Changes successfully applied.');  // eslint-disable-line
      resolver.resolve(result);
    },
    (error) => {
      console.debug('[P13N INNER][CHANGE HANDLER] Error applying changes.');  // eslint-disable-line
      console.debug(error);  // eslint-disable-line
      resolver.reject(error);
    },
  );

  return resolver;
}

/**
 * Transform the provided changeSet to the format expected by change applier.
 *
 * IMPORTANT! - This logic should match the logic used in /client/build.py
 *              that is used to build the client js code with the exception
 *              of behavior that is expected to work differently in the editor.
 *
 * This includes:
 *  - Wrap custom code in a function def as custom code changes should be executable functions
 *  - Wrap custom css in a <style> tag and make it an append change type
 *  - Omit redirect changes when working in the editor
 *  - Separate rearrange changes out from element changes into a separate change type.
 *  - Set the change type for insert HTML/Image to an append change type
 *
 * @param {array} changeSet
 * @returns {array}
 */
export function transformChangeSet(changeSet) {
  const changeApplier = window.optimizely.get('client-change-applier');
  let previousDependentChangeId;
  let containsRedirectChange = false;
  const transformedChangeSet = changeSet.map((change) => {
    // If the prior change in the list had a rearrange change, add that dependency to this change.
    if (previousDependentChangeId) {
      change.dependencies.push(previousDependentChangeId);
      previousDependentChangeId = null;
    }

    if (change.type === LayerExperimentEnums.ChangeTypes.CUSTOM_CODE) {
      try {
        // TODO: refactor to eliminate use of eval
        // eslint-disable-next-line no-eval
        change.value = eval(`(function($) {${change.value}\n})`);
      } catch (error) {
        // TODO: Alert user that the custom code failed to eval.
        window.console.log('Error evaling custom code... Skipping it');
        return null;
      }
    }

    // Do not display a redirect change
    if (change.type === LayerExperimentEnums.ChangeTypes.REDIRECT) {
      containsRedirectChange = true;
      return null;
    }

    // Change applier applies custom CSS via the APPEND change type of a <style> tag.
    if (change.type === LayerExperimentEnums.ChangeTypes.CUSTOM_CSS) {
      change.type = changeApplier.enums.changeType.APPEND;
      change.value = `<style>${change.value}</style>`;
    }

    // If this is an attribute change with a rearrange specified, pluck out
    // the rearrange properties as a new type to send to the change applier.
    if (change.rearrange && change.rearrange.insertSelector) {
      const rearrangeChangeId = `${change.id}-${changeApplier.enums.changeType.REARRANGE}`;
      const rearrangeChange = {
        id: rearrangeChangeId,
        selector: change.selector,
        type: changeApplier.enums.changeType.REARRANGE,
        insertSelector: change.rearrange.insertSelector,
        operator: change.rearrange.operator,
        dependencies: [change.id],
      };
      delete change.rearrange;
      // Since we added a change to the list, set its changeID for the next change to use as a dependency of.
      previousDependentChangeId = rearrangeChangeId;
      return [change, rearrangeChange];
    }

    if (change.type === LayerExperimentEnums.ChangeTypes.INSERT_HTML || change.type === LayerExperimentEnums.ChangeTypes.INSERT_IMAGE) {
      change.type = changeApplier.enums.changeType.APPEND;
    }
    return change;
  });

  // Do not execute any changes if a redirect change exists. The editor will manually load the redirected page itself.
  if (containsRedirectChange) {
    return [];
  }

  return compact(flattenDeep(transformedChangeSet));
}

/**
 * Start processing the queue and keep running to execute requests as they come in.
 * Additional calls to add will call processQueue again if nothing is currently running.
 */
function processQueue() {
  if (queueIsProcessing || changeQueue.getSize() === 0) {
    return;
  }

  queueIsProcessing = true;

  // Keep processing even if the deferred is rejected since apply changes rejects consecutive requests.
  // Only stop processing when the queue is empty.
  processNextQueueItem().then(processQueueComplete, processQueueComplete);
}

function processQueueComplete() {
  // Set to false here so that processQueue doesn't immediately return and because nothing is currently running.
  queueIsProcessing = false;
  processQueue();
}

/**
 * Helper function to process each item in the queue.
 *
 * @returns {deferred}
 */
function processNextQueueItem() {
  const currentItem = changeQueue.getNext();
  const currentDeferred = currentItem.deferred;

  switch (currentItem.type) {
    case enums.ChangeHandlerRequestQueueType.EXECUTE_WITHOUT_CHANGES:
      invokeWithoutChanges(currentItem);
      break;

    case enums.ChangeHandlerRequestQueueType.APPLY_CHANGES:
      applyChanges(currentItem)
        .then((statusMap) => {
          Object.keys(statusMap).forEach((key) => {
            if (statusMap[key].error) {
              statusMap[key].formattedErrorMessage = formatErrorMessage(statusMap[key].error);
            }
          });

          writeChannel.write({
            type: EditorIframeEnums.IFrameMessageTypes.SET_CHANGE_APPLIER_RESULT,
            payload: {
              id: currentItem.payload.id,
              statusMap,
            },
          });
        }, (error) => {
          window.console.warn('Error executing APPLY_CHANGES.');
          window.console.warn(error);
        });
      break;
    default:
  }

  return currentDeferred;
}

/**
 * Format errorDetails object as an error message string
 * @param {Error} error
 * @return {String}
 */
export function formatErrorMessage(error) {
  /* eslint-disable no-irregular-whitespace */
  const errorDetails = {
    columnNumber: 0,
    inFunction: '',
    lineNumber: 0,
  };

  // Chrome:
  //   error.message: "test is not defined"
  //   error.stack (line 1): "at Object.eval (eval at <anonymous> (https://www.optimizely.test/js/
  //                          devel.779884050243189645/bundle-s/inner.js:66912:10), <anonymous>:21:1)"
  // FF:
  //   error.message: "test is not defined"
  //   error.stack (line 1): "https://www.optimizely.test/js/devel.779884050243189645/bundle-s/inner.js:1396optly.
  //                          custom.evalOrError"
  //   error.lineNumber: 1396
  //   error.columnNumber: 0
  // Safari:
  //   error.message: "Can't find variable: test"
  //   error.stack: "eval@[native code]"
  //   error.line: 21
  // IE:
  //   error.message: ﻿"﻿'test' is undefined"
  //   error.stack: "﻿at eval code (eval code:21:1)"

  // Safari
  if (error.line) {
    errorDetails.lineNumber = error.line;
  }

  if (error.stack) {
    const stack = error.stack;
    const stackLines = stack.split('\n');
    // top of the stack should be the eval
    const stackTop = stackLines[1];
    const match = stackTop.match(/at (\w+).*:(\d+):(\d+)\)$/);
    if (match) {
      // chrome gives super-detailed errors!
      assign(errorDetails, {
        inFunction: match[1],
        lineNumber: match[2],
        columnNumber: match[3],
      });
    }
  }

  let errorMessage = error.message;
  const errorDescriptions = [];
  if (errorDetails.inFunction && errorDetails.inFunction !== 'eval' &&
    errorDetails.inFunction !== 'Object') {
    errorDescriptions.push(`function: ${errorDetails.inFunction}`);
  }
  if (errorDetails.lineNumber) {
    errorDescriptions.push(`line: ${errorDetails.lineNumber}`);
  }
  if (errorDetails.columnNumber) {
    errorDescriptions.push(`col: ${errorDetails.columnNumber}`);
  }
  if (errorDescriptions.length > 0) {
    errorMessage += ` (${errorDescriptions.join(', ')})`;
  }
  return errorMessage;
}

export default {
  pushChangeHandlerRequest,
  transformChangeSet,
  formatErrorMessage,
};
