const Nuclear = require('nuclear-js');
const { toImmutable } = require('optly/immutable');

const actionTypes = require('../action_types');

/**
 * iframe logging store - tracks information about the editor_iframe log used for debugging.
 */
module.exports = Nuclear.Store({
  getInitialState() {
    return toImmutable({
      loggingEnabled: false,
    });
  },

  initialize() {
    this.on(actionTypes.P13N_EDITOR_SET_LOGGING_ENABLED, setLoggingEnabled);
  },
});

/**
 * @param {Immutable.Map} state
 * @param {Object} payload
 * @param {String} payload.enabled
] */
function setLoggingEnabled(state, payload) {
  return state.set('loggingEnabled', payload.enabled);
}
