import clone from 'lodash/clone';
import filter from 'lodash/filter';
import find from 'lodash/find';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import powerset from 'powerset';

import queryHelpers from 'optly/utils/query_selector';
import shadowDomUtils from 'optly/utils/shadow_dom';

/**
 * Selectors that are not valid.
 */
// Todo: Update this for p13n
const INVALID_SELECTORS = {
  classes: [
    '}', // found on chow.com
    'ui-droppable',
  ],
  id_regexes: [/^yui.*/, /^block-yui.*/],
};

const DYNAMIC_SELECTOR_PREFIX = 'dynamic_selector_prefix';

// Attributes to use in generating selectors
const ATTRIBUTES = ['name'];

// Enumeration of selectors and their relative position within an individual CSS selector
// e.g. 'div#someId.someClass[name="someName"]'
const SELECTOR_TYPE = {
  TAG_NAME: 1,
  ID: 2,
  CLASS: 3,
  ATTRIBUTE: 4,
  CUSTOM_ATTRIBUTE: 5,
};

// Put a cap on the # of facets that we'll use for a powerset selector search. The number of
// dom queries in a powerset search grows exponentially -- 2^<number of facets>
const FACET_LENGTH_LIMIT = 6;

/**
 * Escape invalid characters in a css selector. See https://mathiasbynens.be/notes/css-escapes
 * @param {string} selector
 * @returns {array}
 */
function escapeSelector(selector) {
  // Escape CSS special characters.
  selector = selector.replace(/([!"#$%&'()*+,./:;<=>?@[\\\]^`{|}~])/g, '\\$1');
  const firstCharacter = selector.match(/[a-zA-Z0-9]/);

  // Escape leading digits.
  if (firstCharacter && !Number.isNaN(parseInt(firstCharacter, 10))) {
    selector = selector.replace(firstCharacter, `\\3${firstCharacter} `);
  }
  return selector;
}

function escapeAttribute(attribute) {
  return attribute.replace("'", "'");
}

/**
 * @param {jQueryObject} element
 * @private
 * @return {string} lower case, metacharacter escaped tag name
 */
function getProperTagName(element) {
  if (isUndefined(element.tagName)) {
    return null;
  }
  return escapeSelector(element.tagName.toLowerCase());
}

/**
 * Generate a selector based off an elements id
 * @param {DOMElement} element
 * @returns {array}
 */
function generateIdSelector(element) {
  let id = element.id;
  if (isString(id)) {
    id = id.trim();

    for (let x = 0; x < INVALID_SELECTORS.id_regexes.length; x++) {
      if (INVALID_SELECTORS.id_regexes[x].test(id)) {
        return null;
      }
    }

    if (id.length > 0) {
      // Escape any meta-characters. (e.g. :)
      return `#${escapeSelector(id)}`;
    }
  }
  return null;
}

/**
 * Generate selectors based off of an elements class names. Doesn't do any combinatorial work
 * @param {DOMElement} element
 * @returns {array}
 */
function generateClassSelectors(element) {
  const selectors = [];

  // Get list of class names
  const classes = filter(
    (element.getAttribute('class') || '').replace(/\{.*\}/, '').split(/\s/),
  );
  classes.forEach(currentClass => {
    // Skip invalid classes.
    if (includes(INVALID_SELECTORS.classes, currentClass)) {
      return;
    }
    selectors.push(`.${escapeSelector(currentClass)}`);
  });

  return selectors;
}

/**
 * Generate selectors based off of an elements attributes names
 * @param {DOMElement} element
 * @returns {array}
 */
function generateAttributeSelectors(element) {
  const selectors = [];
  ATTRIBUTES.forEach(attr => {
    const value = element.getAttribute(attr);
    if (value) {
      selectors.push(`[${attr}='${escapeAttribute(value)}']`);
    }
  });
  return selectors;
}

/**
 * Generate selectors based off of an elements custom attributes properties
 * @param {DOMElement} element
 * @returns {array}
 */
function generateCustomAttributeSelectors(element, prefix) {
  const selectors = [];

  element
    .getAttributeNames()
    .filter(attrName => attrName.startsWith(prefix))
    .forEach(attr => {
      const value = element.getAttribute(attr);
      if (value) {
        selectors.push(`[${attr}='${escapeAttribute(value)}']`);
      }
    });

  return selectors;
}

/**
 * Get all "facets", or single identifier selectors, from an element. Returns facets in the following format:
 *   [
 *     {
 *       selector: '.className',
 *       type: 3,
 *     }
 *     {
 *       selector: '[name="name"]',
 *       type: 4,
 *     },
 *     ...
 *   ]
 * @param {HTMLDomElement} element
 * @returns {Object[]}
 */
function generateUniqueSelectorFacets(element, dynamicSelectorPrefix) {
  const facets = [];

  const tagNameSelector = getProperTagName(element);
  if (tagNameSelector) {
    facets.push({
      selector: tagNameSelector,
      type: SELECTOR_TYPE.TAG_NAME,
    });
  }

  if (dynamicSelectorPrefix) {
    const customAttributeSelectors = generateCustomAttributeSelectors(
      element,
      dynamicSelectorPrefix,
    );

    customAttributeSelectors.forEach(selector => {
      facets.push({
        selector,
        type: SELECTOR_TYPE.CUSTOM_ATTRIBUTE,
      });
    });
  } else {
    const idSelector = generateIdSelector(element);
    if (idSelector) {
      facets.push({
        selector: idSelector,
        type: SELECTOR_TYPE.ID,
      });
    }

    const classSelectors = generateClassSelectors(element);
    classSelectors.forEach(selector => {
      facets.push({
        selector,
        type: SELECTOR_TYPE.CLASS,
      });
    });

    const attributeSelectors = generateAttributeSelectors(element);
    attributeSelectors.forEach(selector => {
      facets.push({
        selector,
        type: SELECTOR_TYPE.ATTRIBUTE,
      });
    });
  }

  return facets;
}

/**
 * Transform something like ['div.classOfClickedElement', 'div'] to 'div > div.classOfClickedElement'
 * @param {array} selectorHierarch
 */
function createSelectorFromHierarchyArray(selectorHierarchy) {
  return clone(selectorHierarchy)
    .reverse()
    .join(' ')
    .trim();
}

/**
 * Check to see if a selector is unique in the dom
 * @param {string} selector
 * @param {DOMElement} parent
 * @private
 * @returns {boolean}
 */
function isUniqueSelector(selector, parent) {
  if (!parent) {
    return queryHelpers.querySelectorAll(selector).length === 1;
  }
  return queryHelpers.querySelectorAll(selector, parent).length === 1;
}

/**
 * Sanity check to see that the generated selector matches the element
 * @param {HTMLDomElement} element
 * @param {string} selector
 * @returns {boolean}
 */
function validateSelector(element, selector) {
  const foundElements = queryHelpers.querySelectorAll(selector);
  if (foundElements.length !== 1 || element !== foundElements[0]) {
    console.log(`Generated selector doesnt match element: ${selector}`);
    return false;
  }
  return true;
}

/**
 * Given a list of facet objects, combine them into a single selector
 * @param {Object[]} facets
 * @returns {string}
 */
function createSelectorFromFacetArray(facets) {
  return map(sortBy(facets, 'type'), 'selector').join('');
}

/**
 * Given an element, return a unique selector or, if none exist, the most specific selector
 * we can manage.
 *
 * The basic strategy for this is as follows:
 *   1) Check to see that we are dealing with a operable DOM element (eg not window.document)
 *   2) Retrieve all individual unique selectors for an element (eg. ".class-1", "#someId", etc)
 *   3) Check to see how many elements are returned using the most specific selector we can manage,
 *      ie the combination of all of the above selectors
 *   4) If there is a reasonable # of individual selectors, use a brute force approach to determine the
 *      shortest possible unique selector (as judged by the character length of the selector)
 *   5) If there is a large # of individual selectors, use a heuristic to find a short-ish selector.
 *   6) Return the best selector found from step 4 or 5
 *
 * @param {HTMLDomElement} element
 * @returns {string|null}
 */
export function generateMostSpecificSelector(element, dynamicSelectorPrefix) {
  let selectorType;

  // There is no selector for the document
  if (element === document) {
    return { selector: null };
  }

  // Retrieve all distinguishable facets
  let facets = generateUniqueSelectorFacets(element, dynamicSelectorPrefix);

  const tagFacet = find(facets, facet => facet.type === SELECTOR_TYPE.TAG_NAME);

  // Ported from old selectorator. Unsure what case this is catching
  if (!tagFacet) {
    return { selector: null };
  }

  // No need to generate selectors beyond the body/html tags -- these are guaranteed to be unique
  if (tagFacet.selector === 'body' || tagFacet.selector === 'html') {
    return { selector: tagFacet.selector };
  }

  if (dynamicSelectorPrefix) {
    const customAttributeFacet = find(
      facets,
      facet => facet.type === SELECTOR_TYPE.CUSTOM_ATTRIBUTE,
    );

    if (customAttributeFacet) {
      selectorType = SELECTOR_TYPE.CUSTOM_ATTRIBUTE;
    }

    // Return (unique) customAttributeFacet selector if it exists
    if (
      customAttributeFacet &&
      isUniqueSelector(customAttributeFacet.selector)
    ) {
      return {
        selector: customAttributeFacet.selector,
        selectorType,
      };
    }
  } else {
    const idFacet = find(facets, facet => facet.type === SELECTOR_TYPE.ID);

    // Return (unique) id selector if it exists
    if (idFacet && isUniqueSelector(idFacet.selector)) {
      return { selector: idFacet.selector };
    }
  }

  // Check to see if there is a unique selector
  let bestSelector = createSelectorFromFacetArray(facets);
  const minimumNumberOfMatches = queryHelpers.querySelectorAll(bestSelector)
    .length;

  // If we have a reasonable number of facets, use the powerset to find the shortest one
  if (facets.length < FACET_LENGTH_LIMIT) {
    // powerset returns an array of all possible combinations of facets. This is a brute force
    // approach to finding the shortest one.
    const allPossibleFacetSets = powerset(facets);

    // Start at index 1 to skip the empty set
    for (let x = 1; x < allPossibleFacetSets.length; x++) {
      // Take current facet array, condense into a usable selector
      const currentSelector = createSelectorFromFacetArray(
        allPossibleFacetSets[x],
      );

      // If the selector is shorter than the current best selector, test for uniqueness
      if (currentSelector.length < bestSelector.length) {
        if (
          queryHelpers.querySelectorAll(currentSelector).length ===
          minimumNumberOfMatches
        ) {
          bestSelector = currentSelector;
        }
      }
    }

    // If we dont have a reasonable number of facets, use a heuristic to find a short one. Works as follows:
    //
    // Loop through each facet and query with it individually to see how many elements it returns.
    // Once we've ordered the facets from most to least specific, loop through the list and start concatenating
    // facets. Eventually we'll find one that is unique (as guaranteed by the uniqueness check above).
    // This is a linear operation, much more performant than the powerset approach, however it is not
    // guaranteed to find the shortest selector.
  } else {
    // Get # of elements that each facet returns and store it. If we happen to find one that returns
    // one element, then return it as it is the unique selector we were looking for.
    for (let y = 0; y < facets.length; y++) {
      const nodeList = queryHelpers.querySelectorAll(facets[y].selector);
      if (nodeList.length === minimumNumberOfMatches) {
        return { selector: facets[y].selector };
      }
      facets[y].nodeListLength = nodeList.length;
    }

    // Sort facets by # of selectors each facet returned, in ascending order (ie order the array
    // from most specific selector to least specific selector)
    facets = sortBy(facets, 'nodeListLength');

    // Loop through each of the facets and build increasingly complex selectors in an attempt to find
    // a unique one. This optimizes for finding shorter selector strings at the expense of more queries.
    // The heuristic is simple --
    for (let z = 0; z < facets.length; z++) {
      // Sort by type orders the facets such that they are in valid CSS selector format
      bestSelector = createSelectorFromFacetArray(facets.slice(0, z + 1));
      if (
        queryHelpers.querySelectorAll(bestSelector).length ===
        minimumNumberOfMatches
      ) {
        break;
      }
    }
  }
  return { selector: bestSelector, selectorType };
}

/**
 *
 * @param {DOMElement} element
 * @returns {DOMElement|undefined}
 */
export function getClosestParent(element) {
  const parent = element.parentElement;
  if (shadowDomUtils.isShadowDomSupported() && !parent) {
    return shadowDomUtils.getHost(element);
  }
  return parent;
}

/**
 *
 * @param {DOMElement} element
 * @returns {null|string}
 */
export function generateSelector(element) {
  const originalElement = element;
  const selectorHierarchy = [];
  let selector;
  let selectorType;
  let childDescendent = false;

  const dynamicSelectorPrefix =
    window.optly_inner_features &&
    window.optly_inner_features[DYNAMIC_SELECTOR_PREFIX];

  while (getClosestParent(element)) {
    // Retrieve a selector that doesn't rely on DOM hierarchy
    const selectorData = generateMostSpecificSelector(
      element,
      dynamicSelectorPrefix,
    );

    selector = selectorData.selector;
    selectorType = selectorData.selectorType;

    if (!selector) {
      break;
    }

    // if our selector is unique with regards to it's parent's parent, we can use a descendent selector rather than a child selector
    // and skip this step. The exception to this is if the current selector is globally unique. If that's the
    // case, we should use it.
    if (
      !dynamicSelectorPrefix && // When Dynamic Selector is enabled we need to keep the search until the root tag
      selectorHierarchy.length &&
      isUniqueSelector(
        createSelectorFromHierarchyArray(selectorHierarchy),
        getClosestParent(element),
      ) &&
      !isUniqueSelector(selector)
    ) {
      element = getClosestParent(element);
      childDescendent = false;
      // TODO: refactor to eliminate use of continue
      continue; // eslint-disable-line no-continue
    }

    // Push selector onto array -- if there are existing selectors we need to add child descendent selector
    selectorHierarchy.push(selector + (childDescendent ? ' >' : ''));

    // If the chosen selector is not unique within the context of the current parent, modify
    // to use the nth-of-type pseudo selector that guarantees uniqueness
    if (
      !isUniqueSelector(
        createSelectorFromHierarchyArray(selectorHierarchy),
        getClosestParent(element),
      )
    ) {
      const tagName = getProperTagName(element);
      const childrenWithMatchingTag = filter(
        getClosestParent(element).children,
        child => tagName === getProperTagName(child),
      );

      const index = indexOf(childrenWithMatchingTag, element);

      // Remove the original selector we had pushed on
      selectorHierarchy.pop();

      // nth-of-type can not be used with any selectors other than tagName (annoying)
      selectorHierarchy.push(
        `${tagName}:nth-of-type(${index + 1})${childDescendent ? ' >' : ''}`,
      );
    }

    const fullSelector = createSelectorFromHierarchyArray(selectorHierarchy);

    let checkGloballyUnique = true;

    // check if the selector is globally unique if we have a custom attribute or we already are in the root tag
    if (dynamicSelectorPrefix) {
      checkGloballyUnique =
        selectorType === SELECTOR_TYPE.CUSTOM_ATTRIBUTE || selector === 'body';
    }

    // Check to see if the selector is globally unique
    if (
      checkGloballyUnique &&
      isUniqueSelector(fullSelector) &&
      validateSelector(originalElement, fullSelector)
    ) {
      return fullSelector;
    }
    childDescendent = true;
    element = getClosestParent(element);
  }

  // Couldn't find a unique selector
  return null;
}
