/**
 * Editor Loader Module Constants
 */
import keyMirror from 'optly/utils/key_mirror';

export const CSSPropertyNames = {
  BACKGROUND_COLOR: 'background-color',
  BACKGROUND_IMAGE: 'background-image',
  BORDER_COLOR: 'border-color',
  BORDER_STYLE: 'border-style',
  BORDER_WIDTH: 'border-width',
  COLOR: 'color',
  FONT_SIZE: 'font-size',
  FONT_WEIGHT: 'font-weight',
  HEIGHT: 'height',
  POSITION: 'position',
  WIDTH: 'width',
};

export const ElementChangeSections = keyMirror({
  BACKGROUND: null,
  BORDERS: null,
  DIMENSIONS: null,
  ELEMENT_CODE: null,
  LAYOUT: null,
  TIMING: null,
  TRACK_CLICKS: null,
  TYPOGRAPHY: null,
});

/**
 * load status types the iframe component uses to track innie/iframe loading
 */
export const MessageTypes = keyMirror({
  HIGHLIGHT_ELEMENTS: null,
  UNHIGHLIGHT_ELEMENTS: null,
});

/**
 * load status types to keep track of multiple selector inputs used in a
 * single component
 */
export const SelectorInputTypes = keyMirror({
  ELEMENT_SELECTOR: null,
  REARRANGE_SELECTOR: null,
  INSERT_HTML_SELECTOR: null,
  INSERT_IMAGE_SELECTOR: null,
});

/**
 * load status types to keep track of multiple timing dropdowns used in a
 * single component
 */
export const TimingDropdownTypes = keyMirror({
  ELEMENT_TIMING: null,
  CUSTOM_JS_TIMING: null,
  CUSTOM_CSS_TIMING: null,
});

export const SupportedAttributeChanges = {
  class: null,
  href: null,
  html: null,
  src: null,
  style: null,
  text: null,
  remove: null,
  hide: null,
};

export const SupportedCSSProperties = keyMirror({
  'background-color': null,
  'background-image': null,
  'border-color': null,
  'border-style': null,
  'border-width': null,
  color: null,
  'font-size': null,
  'font-weight': null,
  height: null,
  position: null,
  width: null,
});

/**
 * Message used as a response when an element has an invalid background image
 */
export const INVALID_BACKGROUND_IMAGE = 'INVALID_BACKGROUND_IMAGE';

export const EmittedEventTypes = keyMirror({
  SELECT_ACTION: null,
  SELECT_ACTION_EVERYONE_ELSE: null,
  INITIALIZE_CHANGE_LIST: null,
  SHOW_CAMPAIGN_NAV_SIDEBAR: null,
});

export const EVERYONE_ELSE_AUDIENCE = {
  variation_id: -1,
  name: tr('Holdback'),
  urlParam: 'holdback',
};

export const IMAGE_PROPERTIES = {
  height: 0,
  width: 0,
  thumbnailHeight: 0,
  thumbnailWidth: 0,
  size: null,
  absoluteUrl: null,
  filename: null,
  displayValue: null,
  editingEnabled: false,
};

export const IMAGE_DEBOUNCE_INTERVAL = 800;

export const DEFAULT_REDIRECT_DESTINATION_FUNCTION =
  'function redirectFn() {\n\treturn window.location.pathname.replace(/\\/$/, "") + "/new-final-path" + window.location.search;\n}';

export default {
  CSSPropertyNames,
  ElementChangeSections,
  MessageTypes,
  SelectorInputTypes,
  TimingDropdownTypes,
  SupportedAttributeChanges,
  SupportedCSSProperties,
  INVALID_BACKGROUND_IMAGE,
  EmittedEventTypes,
  EVERYONE_ELSE_AUDIENCE,
  IMAGE_PROPERTIES,
  IMAGE_DEBOUNCE_INTERVAL,
  DEFAULT_REDIRECT_DESTINATION_FUNCTION,
};
