import keyMirror from 'optly/utils/key_mirror';

/**
 * NOTE: These enums are only used for filtering on the frontend
 * They do NOT represent LayerExperiment.status in the backend
 */
export const statusFilter = keyMirror({
  ACTIVE: null,
  ARCHIVED: null,
  NOT_STATED: null,
  PAUSED: null,
  RUNNING: null,
  CONCLUDED: null,
});

/**
 * Enumerable field values for layer_experiments
 */
export const status = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  PAUSED: 'paused',
  CONCLUDED: 'concluded',
};

export const ActualStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  NOT_STARTED: 'not_started',
  PAUSED: 'paused',
  RUNNING: 'running',
  CAMPAIGN_PAUSED: 'campaign_paused',
  CONCLUDED: 'concluded',
};

export const oasis_status = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DRAFT: 'draft',
  PAUSED: 'paused',
  RUNNING: 'running',
};

export const VariationStatus = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
};

// Potential values of the status property inside the environments property of
// LayerExperiment entities.
// The environments property is a Map from environment ID to a Map of
// information about the experiment with respect to that environment,
// including status
export const EnvironmentStatus = {
  ARCHIVED: 'archived',
  NOT_STARTED: 'not_started',
  RUNNING: 'running',
  PAUSED: 'paused',
};

/**
 * Types of custom code editors in the campaign editor.
 */
export const CustomCodeTypes = keyMirror({
  CSS: null,
  JAVASCRIPT: null,
});

/**
 * Possible statuses for a change.
 * These are calculated dynamically by the frontend and aren't stored in the GAE model.
 */
export const ChangeStatuses = {
  INITIAL: 'initial', // Before the change is ever persisted to an action (on initial element click)
  NEW: 'new', // Once a new change is saved, meaning there is no corresponding live change
  DIRTY: 'dirty', // A change that is currently being edited whose value is not the same as the datastore
  LIVE: 'live', // A change that is live but does not have a corresponding draft change
  MODIFIED: 'modified', // A change that is live but also has a corresponding draft change
  DRAFT: 'draft', // The UI simplification for a change that is either NEW or MODIFIED
  DELETED: 'deleted', // A change that is live but has been set to be deleted on the next publish
};

/**
 * Types of changes that can be stored in an action.
 */
export const ChangeTypes = {
  ATTRIBUTE: 'attribute',
  CUSTOM_CODE: 'custom_code',
  CUSTOM_CSS: 'custom_css',
  INSERT_HTML: 'insert_html',
  INSERT_IMAGE: 'insert_image',
  WIDGET: 'widget',
  REDIRECT: 'redirect',
};

/**
 * Types of bucketing strategies that can be saved for an experiment.
 */
export const BucketingStrategies = {
  IMPRESSION: 'impression',
  LIFETIME: 'lifetime',
};

/**
 * The priority ordering for ChangeTypes.
 *
 * Changes of the types below should
 * be saved in the actions list in this priority.
 *
 * Change types not listed here don't have a priority
 * and should be saved in the order the user provides.
 */
export const ChangeTypePriority = [
  ChangeTypes.CUSTOM_CODE,
  ChangeTypes.CUSTOM_CSS,
  ChangeTypes.REDIRECT,
];

/**
 * Possible traffic policies for Multivariate tests.
 */
export const multivariateTrafficPolicies = {
  FULL_FACTORIAL: 'full_factorial',
  PARTIAL_FACTORIAL: 'partial_factorial',
  TAGUCHI: 'taguchi',
};

export default {
  ActualStatus,
  BucketingStrategies,
  ChangeStatuses,
  ChangeTypePriority,
  ChangeTypes,
  CustomCodeTypes,
  EnvironmentStatus,
  multivariateTrafficPolicies,
  oasis_status,
  status,
  statusFilter,
  VariationStatus,
};
