const parseurl = require('parseurl');
const qs = require('qs');

/**
 * Extracts a JS object of query params from a string
 */
function queryParamsToObject(location) {
  const url = parseurl({ url: location });
  return qs.parse(url.query);
}

module.exports = function() {
  return module.exports.queryParamsToObject.apply(this, arguments);
};

// Allow stubbing of this module which exports a single function for testing purposes.
module.exports.queryParamsToObject = queryParamsToObject;
