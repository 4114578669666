import ShadowDomUtils from './shadow_dom';

/**
 * Wrapper for document.querySelector that catches errors
 * @param {string} selector
 * @param {HTMLDomElement?} parent If specified, equivalent to parent.querySelector(selector)
 * @returns {HTMLDomElement|null}
 */
const querySelector = function(selector, parent) {
  if (!selector) {
    return null;
  }
  try {
    return ShadowDomUtils.isShadowDomSupported()
      ? ShadowDomUtils.querySelector(selector, parent)
      : (parent || document).querySelector(selector);
  } catch (e) {
    return null;
  }
};

/**
 * Wrapper for document.querySelectorAll that catches errors
 * @param {string} selector
 * @param {HTMLDomElement?} parent If specified, equivalent to parent.querySelectorAll(selector)
 * @returns {NodeList|[]} Returns an empty array if the selector throws an error
 */
const querySelectorAll = function(selector, parent) {
  if (!selector) {
    return [];
  }
  try {
    return ShadowDomUtils.isShadowDomSupported()
      ? ShadowDomUtils.querySelectorAll(selector, parent)
      : (parent || document).querySelectorAll(selector);
  } catch (e) {
    return [];
  }
};

export default {
  querySelector,
  querySelectorAll,
};
