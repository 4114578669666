import { querySelector, querySelectorAll } from 'kagekiri';

const SHADOW_DOM_FEATURE_NAME = 'shadow_dom';

function isShadowDomSupported() {
  return (
    window.optly_inner_features &&
    window.optly_inner_features[SHADOW_DOM_FEATURE_NAME]
  );
}

/**
 * Evaluates if an element has a shadow root and obtains its host
 * otherwise returns null
 * @param {HTMLElement} element
 * @returns {Node | null} Element's host or null
 */
function getHost(element) {
  return element.getRootNode ? element.getRootNode().host : null;
}

/**
 * Evaluates if a node is a shadowRoot
 * and obtains an element give a point on the page
 *
 * @param node The shadow root host to inspect
 * @param x x-coordinate of the point
 * @param y y-coordinate of the point
 * @return {Element | undefined} Element found
 */
function shadowRootElementFromPoint(node, x, y) {
  let element;
  if (node && node.shadowRoot) {
    element = node.shadowRoot.elementFromPoint(x, y);
  }
  return element;
}

/**
 * Finds the element at a given point on the page, taking
 * shadow roots into account.
 *
 * @param x x-coordinate of the point
 * @param y y-coordinate of the point
 * @return {Element} Element found
 */
function elementFromPoint(x, y) {
  let node = document.elementFromPoint(x, y);
  let child = shadowRootElementFromPoint(node, x, y);

  while (child && child !== node) {
    node = child;
    child = shadowRootElementFromPoint(node, x, y);
  }

  return child || node;
}

export default {
  elementFromPoint,
  isShadowDomSupported,
  getHost,
  querySelector,
  querySelectorAll,
};
